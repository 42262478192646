/* @flow */

export const APP_NAME = 'Renote';

export const LANG = 'en';

export const TIME_ZONE = 'en-EN';

export const GMAPS_API_KEY = 'AIzaSyBXNDm0zCJz86EuiFmdjmrGeTlgK9WJ3T4';

export const ANDROID_VERSIONS = {
  LOLLIPOP: 21
};

export const isCNDomain = () => {
  // return !window.location.hostname.includes('.net');
  return window.location.hostname.includes('.cn');
}

export const getMainDomain = () => {
  let fullDomain = window.location.hostname;
  let domainParts = fullDomain.split('.'); // 拆分域名
  let mainDomain = domainParts[domainParts.length - 2] + '.' + domainParts[domainParts.length - 1];

  return mainDomain;
}

export const GOOGLE_ANALYTICS_TRACKID = 'G-RYTCZEQK0W';

export const MOBILE_MEDIA_QUERY = { query: '(max-width: 900px)' }