import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { motion, AnimatePresence } from 'framer-motion';
import { SETTINGS_DIALOG } from '../../constants/actionTypes';
import { Settings } from '@styled-icons/remix-line/Settings'
import { Tooltip } from '@mui/material';
import { isMac } from '../../constants/constants';

const FloatingTipsPanel = ({ user_behavior }) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const flow_settings = useSelector(state => state.uiState.flow_settings);
    const [currentTipIndex, setCurrentTipIndex] = useState(0);

    // Generate tips based on current settings
    const generateTips = () => {
        let tips = [];

        if (flow_settings?.dragBehavior === 'selection') {
            tips.push({
                id: 'drag_pan_hint',
                text: intl.formatMessage(
                    { id: 'flow_settings_pan_hint' },
                    { key: flow_settings?.panKey || 'Space' }
                )
            });
        } else {
            tips.push({
                id: 'drag_selection_hint',
                text: intl.formatMessage(
                    { id: 'flow_settings_selection_hint' },
                    { key: flow_settings?.selectionKey || 'Shift' }
                )
            });
        }

        if (flow_settings?.scrollBehavior !== 'zoom') {
            tips.push({
                id: 'scroll_zoom_hint',
                text: intl.formatMessage(
                    { id: 'flow_settings_zoom_hint' },
                    { key: flow_settings?.zoomKey == 'Meta' && 'Control/Command' || flow_settings?.zoomKey || (isMac ? 'Control/Command' : 'Control') }
                )
            });
            tips.push({
                id: 'scroll_pan_hint',
                text: intl.formatMessage({ id: 'flow_settings_scroll_pan_horizontal_hint' })
            });
        }

        tips = tips.concat([{
            id: 'paste_to_board_hint',
            text: intl.formatMessage({ id: 'paste_to_board_hint' })
        }, {
            id: "keyboard_save_hint",
            text: intl.formatMessage({ id: 'keyboard_save_hint' })
        }])

        return tips;
    };

    const tips = useMemo(() => generateTips(), [flow_settings, intl]);
    const [displayedTip, setDisplayedTip] = useState();
    useEffect(() => {
        if (!tips?.length) return;

        if (!user_behavior) {
            const interval = setInterval(() => {
                setCurrentTipIndex((prevIndex) => (prevIndex + 1) % tips.length);
            }, 5000);

            return () => clearInterval(interval);
        } else {
            const priority_tips = tips?.filter(tip => tip.id?.includes(user_behavior));
            if (priority_tips?.length) {
                setCurrentTipIndex(tips.findIndex(tip => tip.id === priority_tips[Math.floor(Math.random() * priority_tips.length)].id))
            }
        }
    }, [tips?.length, user_behavior]);

    useEffect(() => {
        if(!tips?.length) return;
        setDisplayedTip(tips[currentTipIndex || 0])
    }, [currentTipIndex])

    if (!displayedTip) return null;

    return (
        <div className="bg-white bg-opacity-70"
            style={{
                boxShadow: '0px 0px 8px #bbb',
                borderRadius: 5,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                columnGap: 5,
                padding: 5,
                paddingLeft: 5,
                paddingRight: 12
            }}
        >
            <Tooltip title={intl.formatMessage({ id: 'flow_settings' })} placement="bottom">
                <div
                    className='hoverStand'
                    style={{
                        padding: 6,
                    }}
                    onClick={() => {
                        dispatch({ type: SETTINGS_DIALOG, value: { visible: true, app: 'flow', page: 'flow_settings' } });
                    }}
                >
                    <Settings size={18} />
                </div>
            </Tooltip>
            <AnimatePresence mode="wait">
                <motion.div
                    key={currentTipIndex}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -20 }}
                    transition={{ duration: 0.5 }}
                >
                    <p className="text-sm font-medium text-gray-700">{displayedTip.text}</p>
                </motion.div>
            </AnimatePresence>
        </div>
    );
};

export default FloatingTipsPanel;