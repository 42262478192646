
import { KeyboardReturn } from '@styled-icons/material/KeyboardReturn';
import { useEffect, useRef, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, FormControl, FormControlLabel, FormLabel, Popover, Radio, RadioGroup, TextareaAutosize } from "@mui/material";
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { IMAGE_UPLOAD_DIALOG, OPERATION_FAILED } from 'src/constants/actionTypes';
import { uploadImgs } from 'src/actions/ticketAction';
import { Link, useHistory, useLocation } from 'react-router-dom';
import PromoteVIP from '../settings/PromoteVIP';

export const ImageUploadModal = ({ isMobile }) => {
    const intl = useIntl();
    const currentLocation = useLocation();
    const params = new Proxy(new URLSearchParams(currentLocation.search), {
        get: (searchParams, prop) => searchParams.get(prop),
    });

    const dialogState = useSelector(state => state.uiState.imageUploadDialog) || { visible: false };
    const promote_vip = useSelector(state => state.uiState.promote_vip) || {};
    const dispatch = useDispatch();
    const hid = dialogState.hid || params.hid;

    const [image, setImage] = useState({});

    const handleClose = () => {
        dispatch({ type: IMAGE_UPLOAD_DIALOG, value: { visible: false } });
    }

    const confirmInsert = (image) => {
        if (!image.link) {
            return;
        }

        dispatch({
            type: IMAGE_UPLOAD_DIALOG,
            value: {
                ...dialogState,
                visible: false,
                image
            }
        })
    }

    const uploadImage = async () => {
        if (!image.file) {
            return;
        }

        dispatch(uploadImgs({ files: [image.file], hid, enctype: 'multipart' }, (files) => {
            if (!files || !files.length || !files[0].uri) {
                return dispatch({
                    type: OPERATION_FAILED,
                    message: intl.formatMessage({ id: 'upload_failed' })
                });
            }

            confirmInsert({
                ...image,
                link: files[0].uri
            })
        }, 'editor'));
    }

    const handleConfirm = () => {
        if (image.type === 'link') {
            confirmInsert(image);
        } else {
            uploadImage();
        }
    }

    useEffect(() => {
        if (dialogState.visible) {
            setImage({});
        }
    }, [dialogState.visible])

    return (
        <Dialog
            open={!!dialogState && dialogState.visible}
            onClose={handleClose}
            scroll='paper'
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            maxWidth='md'
            style={{
                zIndex: 100,
            }}

            onKeyDown={(event) => {
                if (event.key === 'Enter') {
                    handleConfirm();
                    event.preventDefault();
                    event.stopPropagation();
                    return true;
                }
            }}
        >
            <DialogContent dividers={true} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: 0, width: isMobile? undefined: 400,  backgroundColor: 'white' }}>
                <div
                    className='fill-available'
                    style={{ width: '-webkit-fill-available', margin: '14px', marginRight: '20px', marginTop: '18px', marginBottom: '10px', rowGap: '16px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}
                >
                    <FormControl>
                        {/* <FormLabel id="demo-row-radio-buttons-group-label">{intl.formatMessage({ id: 'ai_providers' })}</FormLabel> */}
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={image.type || 'file'}
                            onChange={(event) => {
                                setImage({
                                    ...image,
                                    type: event.target.value
                                })
                            }}
                        >
                            <FormControlLabel value="file" control={<Radio />} label={intl.formatMessage({ id: 'upload_file' })} />
                            <FormControlLabel value="link" control={<Radio />} label={intl.formatMessage({ id: 'link' })} />
                        </RadioGroup>
                    </FormControl>

                    {
                        image.type === 'link' &&
                        <div style={{ display: 'flex', flexDirection: 'row', columnGap: '6px', alignItems: 'center' }}>
                            <div style={{ width: 60, textAlign: 'right' }}>{intl.formatMessage({ id: 'link' })}</div>
                            <input
                                id="link"
                                type="url"
                                value={image.link || ''}
                                onChange={(event) => setImage({
                                    ...image,
                                    link: event.target.value
                                })}
                                className='fill-available'
                                style={{
                                    padding: '4px',
                                    width: '-webkit-fill-available',
                                    outline: 'none',
                                    border: 'solid 1px lightgray', borderRadius: '3px'
                                }}
                            />
                        </div>
                    }
                    {
                        image.type !== 'link' &&
                        <div>
                            <input
                                type="file"
                                id="image"
                                name="image"
                                accept="image/*"
                                // style={{ border: 'solid 1px lightgray', borderRadius: '3px' }}
                                // value={image.file || ''}
                                onChange={(event) => {
                                    setImage({
                                        ...image,
                                        file: event.target.files[0]
                                    })
                                }}
                            />
                        </div>
                    }
                    <div style={{ display: 'flex', flexDirection: 'row', columnGap: '6px', alignItems: 'center' }}>
                        <div style={{ width: 60, textAlign: 'right', whiteSpace: 'nowrap' }}>
                            {intl.formatMessage({ id: 'alt_text' })}
                        </div>
                        <input
                            value={image.altText || ''}
                            onChange={(event) => setImage({
                                ...image,
                                altText: event.target.value
                            })}
                            className='fill-available'
                            style={{
                                padding: '4px',
                                width: '-webkit-fill-available',
                                outline: 'none',
                                border: 'solid 1px lightgray', borderRadius: '3px'
                            }}
                        />
                    </div>
                    {
                        promote_vip?.visible && <PromoteVIP onClose={() => handleClose()} />
                    }
                </div>
                <div
                    className='fill-available'
                    style={{ paddingRight: '20px', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginBottom: '8px', columnGap: '10px' }}
                >
                    <Button variant='text' onClick={handleClose}>{intl.formatMessage({ id: 'cancel' })}</Button>
                    <Button variant='contained' onClick={handleConfirm}>{intl.formatMessage({ id: 'confirm' })}</Button>
                </div>
            </DialogContent>

        </Dialog>
    );
}