import { useIntl } from "react-intl"
import PrivilegeItem from "./PrivilegeItem"

const PrivilegesAI = ({ privileges_primitive, getQuotaText, capitalizeFirstLetter, sku }) => {
    const intl = useIntl();

    if (!privileges_primitive) return;

    return <>
        <div className="fill-available" style={{ height: 1, marginBottom: 18, marginTop: 18, backgroundColor: '#eee' }}></div>

        <div style={styles.advantage_title}>{intl.formatMessage({ id: 'advantage_of_product' }, { product: capitalizeFirstLetter(sku.service_level) })}</div>

        <div style={styles.advantage_title}>{intl.formatMessage({ id: 'more_queries_per_day' })}</div>
        <PrivilegeItem text={intl.formatMessage({ id: 'query_quota' }, { quota: getQuotaText({ ...privileges_primitive?.askAI, value: privileges_primitive?.askAI?.t1 }, intl), model_level: 'Advanced AI queries' })} />
        <PrivilegeItem text={intl.formatMessage({ id: 'query_quota' }, { quota: getQuotaText({ ...privileges_primitive?.askAI, value: privileges_primitive?.askAI?.t2 }, intl), model_level: 'Standard AI queries' })} />

        <div className="fill-available" style={{ height: 1, marginLeft: 26, marginRight: 26, marginBottom: 4, marginTop: 4, backgroundColor: '#eee' }}></div>

        <div style={styles.advantage_title}>{intl.formatMessage({ id: 'more_capacity' })}</div>
        <PrivilegeItem text={intl.formatMessage({ id: 'memos_capacity' }, { quota: getQuotaText(privileges_primitive?.memos, intl) })} desc={intl.formatMessage({ id: 'memos_capacity_desc' })} />

        <div className="fill-available" style={{ height: 1, marginLeft: 26, marginRight: 26, marginBottom: 4, marginTop: 4, backgroundColor: '#eee' }}></div>

        <div style={styles.advantage_title}>{intl.formatMessage({ id: 'advanced_features' })}</div>
        <PrivilegeItem text={intl.formatMessage({ id: 'ai_flow' })} />
        <PrivilegeItem text={intl.formatMessage({ id: 'ai_slides' })} />
        <PrivilegeItem text={intl.formatMessage({ id: 'reading_assistant' })} />
        <PrivilegeItem text={intl.formatMessage({ id: 'writing_assistant' })} />
        <PrivilegeItem text={intl.formatMessage({ id: 'other_llm_providers_enabled' })}
            // desc={intl.formatMessage({ id: 'other_llm_providers_enabled_desc' })}
        />
        <PrivilegeItem text={intl.formatMessage({ id: 'build_ai_apps' })} />

        <div className="fill-available" style={{ height: 1, marginLeft: 26, marginRight: 26, marginBottom: 4, marginTop: 4, backgroundColor: '#eee' }}></div>
        <div style={styles.advantage_title}>{intl.formatMessage({ id: 'other_benefits' })}</div>
        <PrivilegeItem text={intl.formatMessage({ id: 'no_traffic_control' })} />
        <PrivilegeItem text={intl.formatMessage({ id: 'technical_support' })} />
    </>
}

const styles = {
    advantage_title: {
        fontWeight: 600,
        fontSize: 15,
        paddingTop: 8,
        paddingBottom: 8
    }
}

export default PrivilegesAI