/* @flow */

import * as types from '../constants/actionTypes';
import { api_fetch, clearAccessCookie, funblocks_api_fetch } from '../utils/serverAPIUtil';
import * as Constants from './../constants/constants';
import { init_list_state, isFetching, getListTopItemField } from '../reducers/listReducer';

export const uploadImgs = (params, callback, screen) => upsertItem({
  apiAddr: 'media/upload', params, successHandler: {
    callback,
    navBack: false
  }, screen
});

export const uploadFiles = (params, successCallback, failureCallback, screen) => upsertItem({
  apiAddr: 'doc/uploadFiles',
  params,
  successHandler: {
    callback: successCallback,
    action_types: [{ type: types.DOC_ACTIONS.updated }],
    actions: [(item) => {
      let type = types.PRIVATE_DOCS_ACTIONS.added;
      if (params.data.space === 'workspace') {
        type = types.WORKSPACE_DOCS_ACTIONS.added;
      }

      return {
        type,
        _id: item._id,
        item,
        params: { pageBy: 'orderFactor' },
        key: item.orgId
      };
    }],
    navBack: false
  },
  failureCallback,
  screen
});

export const addTicket = (params, successCallback, screen) => upsertItem({
  apiAddr: 'ticket/addTicket', params, successHandler: {
    action_types: [{ type: types.TODOS_ACTIONS.added, list_key: types.LIST_KEYS.todos }],
    callback: successCallback,
    navBack: true
  }, screen
});

export const addSubTicket = (params, list_key, successCallback, screen, dispatch) => {
  let callback = (item) => {
    if (!item) {
      return;
    }

    successCallback();
    dispatch({ type: types.SUB_TICKETS_ACTIONS.added, item: item.ticket, key: list_key });
  }

  return upsertItem({
    apiAddr: 'ticket/addTicket', params, successHandler: {
      action_types: [{ type: types.TODOS_ACTIONS.added, list_key: types.LIST_KEYS.todos }],
      callback,
      navBack: true
    }, screen
  });
}

export const askAI = (params, successCallback, failureCallback, screen) => upsertItem({
  apiAddr: 'ai/askAI', params, successHandler: {
    callback: successCallback,
    action_types: [{ type: types.AIMESSAGES_ACTIONS.added, list_key: types.LIST_KEYS.todos }],
    isSilientAction: true,
    navBack: false
  }, failureCallback, screen
});

export const callAIAssist = (params, successCallback, failureCallback, screen) => upsertItem({
  apiAddr: 'ai/callAIAssistant', params, successHandler: {
    callback: successCallback,
    isSilientAction: true,
    navBack: false
  }, failureCallback, screen
});

export const refreshAIResponse = (params, successCallback, screen) => upsertItem({
  apiAddr: 'ai/refreshAIResponse', params, successHandler: {
    callback: successCallback,
    action_types: params.isChat ? [{ type: types.AIMESSAGES_ACTIONS.added, list_key: types.LIST_KEYS.todos }] : null,
    isSilientAction: true,
    navBack: false
  }, screen
});

export const addURLToRIL = (params, successCallback, failureCallback, screen) => upsertItem({
  apiAddr: 'article/addURLToRIL', params, successHandler: {
    action_types: [{ type: types.RILS_ACTIONS.added, list_key: types.LIST_KEYS.rils }],
    callback: successCallback,
    navBack: true
  }, failureCallback, screen
});

export const moveRILFolder = (item, toFolder, successCallback, screen) => {
  let successHandler = { navBack: false, callback: successCallback, action_types: [] };
  if (item.folder === Constants.RIL_FOLDER.ril) {
    successHandler.action_types.push({ type: types.RILS_ACTIONS.deleted, list_key: types.LIST_KEYS.rils });
  } else if (item.folder === Constants.RIL_FOLDER.read) {
    successHandler.action_types.push({ type: types.READ_RILS_ACTIONS.deleted, list_key: types.LIST_KEYS.rils });
  } else if (item.folder === Constants.RIL_FOLDER.trashbin) {
    successHandler.action_types.push({ type: types.DELETED_RILS_ACTIONS.deleted, list_key: types.LIST_KEYS.rils });
  }

  if (toFolder === Constants.RIL_FOLDER.ril) {
    successHandler.action_types.push({ type: types.RILS_ACTIONS.added, list_key: types.LIST_KEYS.rils });
    successHandler.action_types.push({ type: types.SEARCHED_RILS_ACTIONS.updated, list_key: types.LIST_KEYS.rils });
  } else if (toFolder === Constants.RIL_FOLDER.read) {
    successHandler.action_types.push({ type: types.READ_RILS_ACTIONS.added, list_key: types.LIST_KEYS.rils });
    successHandler.action_types.push({ type: types.SEARCHED_RILS_ACTIONS.updated, list_key: types.LIST_KEYS.rils });
  } else if (toFolder === Constants.RIL_FOLDER.trashbin) {
    successHandler.action_types.push({ type: types.DELETED_RILS_ACTIONS.added, list_key: types.LIST_KEYS.rils });
    successHandler.action_types.push({ type: types.SEARCHED_RILS_ACTIONS.deleted, list_key: types.LIST_KEYS.rils });
  }

  return upsertItem({ apiAddr: 'article/updateRIL', params: { _id: item._id, data: { folder: toFolder, updatedAt: new Date() } }, successHandler, screen });
}

const ril_updated_action = (item, params) => {
  let action_type = types.RILS_ACTIONS.updated;
  if (item.folder === Constants.RIL_FOLDER.read) {
    action_type = types.READ_RILS_ACTIONS.updated;
  } else if (item.folder === Constants.RIL_FOLDER.trashbin) {
    action_type = types.DELETED_RILS_ACTIONS.updated;
  }

  return {
    type: action_type,
    _id: item._id,
    item,
    params,
    key: item.userId
  };
}

export const updateRIL = (params, successCallback, screen) => params && params.data && Object.keys(params.data) && upsertItem({
  apiAddr: 'article/updateRIL',
  params,
  successHandler: {
    navBack: false,
    actions: [(item) => ril_updated_action(item, params)],
    callback: successCallback
  }, screen
});

export const updateRILTags = (params, screen) => upsertItem({
  apiAddr: 'article/updateRILTags',
  params,
  successHandler: {
    navBack: false,
    actions: [(item) => ril_updated_action(item, params)],
  }, screen
});

export const updateRILReadPosition = (params, successCallback, screen) => params && params.data && Object.keys(params.data) && upsertItem({
  apiAddr: 'article/updateRIL',
  params,
  successHandler: {
    navBack: false,
    actions: [(item) => ril_updated_action(item, params)],
    callback: successCallback,
    isSilientAction: true
  }, screen
});

export const uploadHtmlForGathering = (params, screen) => upsertItem({
  apiAddr: 'article/reGatherWithClientHelper', params, successHandler: {
    navBack: false
  }, screen
});

export const highlight = (params, successCallback, failureCallback, screen) => upsertItem({
  apiAddr: 'article/highlight', params, failureCallback, successHandler: {
    action_types: [{ type: types.ARTICLE_HIGHLIGHTS_ACTIONS.added, list_key: params.articleId }],
    callback: successCallback,
    navBack: false
  }, screen
});

export const deleteHighlight = (params, successCallback, screen) => upsertItem({
  apiAddr: 'article/deleteHighlight', params, successHandler: {
    action_types: [{ type: types.ARTICLE_HIGHLIGHTS_ACTIONS.deleted, list_key: params.articleId }],
    callback: successCallback,
    navBack: false
  }, screen
});

export const addHighlightNote = (params, successCallback, screen) => upsertItem({
  apiAddr: 'article/addHighlightNote', params, successHandler: {
    action_types: [{ type: types.ARTICLE_HIGHLIGHTS_ACTIONS.updated, list_key: params.articleId }],
    callback: successCallback,
    navBack: true
  }, screen
});

export const addOKR = (params, successCallback, screen) => upsertItem({
  apiAddr: 'ticket/addTicket', params, successHandler: {
    action_types: [{ type: types.OKRS_ACTIONS.added, list_key: types.LIST_KEYS.okrs }],
    callback: successCallback,
    navBack: true
  }, screen
});

export const publishOKR = (params, screen, dispatch) => {
  let callback = (item) => {
    dispatch({ type: types.TODOS_ACTIONS.deleted, item: item.todo, key: item.userId });
    dispatch({ type: types.DOINGS_ACTIONS.deleted, item: item.todo, key: item.userId });
  }

  return upsertItem({
    apiAddr: 'ticket/addOKR', params, successHandler: {
      callback,
      action_types: [{ type: types.OKRS_ACTIONS.added, list_key: types.LIST_KEYS.okrs }],
      navBack: true
    }, screen
  });
}

export const addKeyResult = (params, list_key, successCallback, screen) => upsertItem({
  apiAddr: 'ticket/addTicket', params, successHandler: {
    action_types: [{ type: types.KEY_RESULTS_ACTIONS.added, list_key }],
    callback: successCallback,
    navBack: true
  }, screen
});

export const deleteSubTicket = (params, list_key, screen) => upsertItem({
  apiAddr: 'ticket/deleteTicket', params, successHandler: {
    action_types: [{ type: types.SUB_TICKETS_ACTIONS.deleted, list_key }],
    navBack: true
  }, screen
});
export const deleteKeyResult = (params, list_key, screen) => upsertItem({
  apiAddr: 'ticket/deleteOKR', params, successHandler: {
    action_types: [{ type: types.KEY_RESULTS_ACTIONS.deleted, list_key }],
    navBack: true
  }, screen
});
export const deleteOKR = (params, screen) => upsertItem({
  apiAddr: 'ticket/deleteOKR', params, successHandler: {
    action_types: [{ type: types.OKRS_ACTIONS.deleted, list_key: types.LIST_KEYS.okrs }],
    navBack: true
  }, screen
});
export const connectTodoToOKR = (params, screen) => upsertItem({
  apiAddr: 'ticket/connectToOKR', params, successHandler: {
    action_types: [{ type: types.TODOS_ACTIONS.updated, list_key: types.LIST_KEYS.okrs }],
    navBack: false
  }, screen
});

export const updateTicket = (params, list_key, successCallback, screen) => upsertItem({
  apiAddr: 'ticket/updateTicket', params, successHandler: {
    action_types: [{ type: types.TICKET_ACTIONS.updated, list_key }],
    callback: successCallback,
    navBack: true
  }, screen
});
export const updateKeyResult = (params, list_key, screen) => upsertItem({
  apiAddr: 'ticket/updateOKR', params, successHandler: {
    action_types: [{ type: types.KEY_RESULTS_ACTIONS.updated, list_key }],
    navBack: false
  }, screen
});
export const updateKRProgress = (params, list_key, screen) => upsertItem({
  apiAddr: 'ticket/updateKRProgress', params, successHandler: {
    action_types: [{ type: types.KEY_RESULTS_ACTIONS.updated, list_key }],
    navBack: false
  }, screen
});
export const updateTodo = (params, screen) => upsertItem({
  apiAddr: 'ticket/updateTodo', params, successHandler: {
    action_types: [{ type: types.TODOS_ACTIONS.updated, list_key: types.LIST_KEYS.todos }],
    navBack: true
  }, screen
});

export const updateTodoTags = (params, screen) => upsertItem({
  apiAddr: 'ticket/updateTodoTags', params, successHandler: {
    action_types: [{ type: types.TODOS_ACTIONS.updated, list_key: types.LIST_KEYS.todos }],
    navBack: true
  }, screen
});

export const deleteTodo = (item, screen) => {
  let successHandler = { navBack: true, action_types: [] };
  if (item.status === Constants.TODO_STATUS.todo) {
    successHandler.action_types.push({ type: types.TODOS_ACTIONS.deleted, list_key: types.LIST_KEYS.todos });
  } else if (item.status === Constants.TODO_STATUS.done) {
    successHandler.action_types.push({ type: types.DONES_ACTIONS.deleted, list_key: types.LIST_KEYS.todos });
  } else if (item.status === Constants.TODO_STATUS.doing) {
    successHandler.action_types.push({ type: types.DOINGS_ACTIONS.deleted, list_key: types.LIST_KEYS.todos });
  }

  successHandler.action_types.push({ type: types.SEARCHED_TODOS_ACTIONS.deleted, list_key: types.LIST_KEYS.todos });

  return upsertItem({ apiAddr: 'ticket/deleteTodo', params: item, successHandler, screen });
}

export const restoreTodo = (item, screen) => {
  return upsertItem({
    apiAddr: 'ticket/restoreTodo', params: item, successHandler: {
      navBack: true,
      action_types: [{ type: types.TODOS_ACTIONS.added, list_key: types.LIST_KEYS.todos },
      { type: types.DELETED_TODOS_ACTIONS.deleted, list_key: types.LIST_KEYS.todos }]
    }, screen
  });
}

export const setTodoStatus = (item, status, screen) => {
  let successHandler = { navBack: false, action_types: [] };
  if (item.status === Constants.TODO_STATUS.todo) {
    successHandler.action_types.push({ type: types.TODOS_ACTIONS.deleted, list_key: types.LIST_KEYS.todos });
  } else if (item.status === Constants.TODO_STATUS.done) {
    successHandler.action_types.push({ type: types.DONES_ACTIONS.deleted, list_key: types.LIST_KEYS.todos });
  } else if (item.status === Constants.TODO_STATUS.doing) {
    successHandler.action_types.push({ type: types.DOINGS_ACTIONS.deleted, list_key: types.LIST_KEYS.todos });
  }

  successHandler.action_types.push({ type: types.SEARCHED_TODOS_ACTIONS.updated, list_key: types.LIST_KEYS.todos });

  if (status === Constants.TODO_STATUS.todo) {
    successHandler.action_types.push({ type: types.TODOS_ACTIONS.added, list_key: types.LIST_KEYS.todos });
  } else if (status === Constants.TODO_STATUS.done) {
    successHandler.action_types.push({ type: types.DONES_ACTIONS.added, list_key: types.LIST_KEYS.todos });
  } else if (status === Constants.TODO_STATUS.doing) {
    successHandler.action_types.push({ type: types.DOINGS_ACTIONS.added, list_key: types.LIST_KEYS.todos });
  }

  item.status = status;
  return upsertItem({ apiAddr: 'ticket/setTodoStatus', params: item, successHandler, screen });
}

export const setTicketStatus = (item, status, comment, successCallback, screen, dispatch) => {
  let callback = (item) => {
    successCallback(item);

    if (!item.statusComment) {
      return;
    }
    dispatch({ type: types.MESSAGES_ACTIONS.added, item: item.statusComment, key: item._id });
  }

  let successHandler = { navBack: false, action_types: [{ type: types.TICKET_ACTIONS.updated, list_key: types.LIST_KEYS.todos }], callback };

  let params = { _id: item._id, status, comment }
  return upsertItem({ apiAddr: 'ticket/setTicketStatus', params, successHandler, screen });
}

export const upsertDoc = (params, callback, showMessage, screen) => {
  params.pageBy = 'orderFactor';
  return upsertItem({
    apiAddr: 'doc/upsert', params, successHandler: {
      isSilientAction: !showMessage,
      callback,
      action_types: [{ type: types.DOC_ACTIONS.updated }],
      actions: [(item) => {
        if (item.newSpace) {
          const SPACE_ACTIONS = {
            workspace: types.WORKSPACE_DOCS_ACTIONS,
            private: types.PRIVATE_DOCS_ACTIONS,
            shared: types.SHARED_DOCS_ACTIONS
          };

          return {
            type: SPACE_ACTIONS[item.newSpace].added,
            _id: item._id,
            item,
            params: { pageBy: 'orderFactor' },
            key: item.orgId
          };
        } else {
          return {
            type: types.SUB_DOCS_ACTIONS.added,
            _id: item._id,
            item,
            params: { pageBy: 'orderFactor' },
            key: item.parent
          };
        }
      }, (item) => {
        if (params.dbDataId && item.dbData) {
          return {
            type: types.DBDATAS_ACTIONS.updated,
            _id: item.dbData._id,
            item: item.dbData,
            params: { pageBy: 'orderFactor' },
            key: item.dbData.hid
          };
        }
        return null;
      }, (item) => {
        if (params.data?.doc?.type == 'flow') {
          return {
            type: types.FLOW_DOCS_ACTIONS.added,
            _id: item._id,
            item,
            key: item.orgId
          }
        }
      }],
      navBack: true
    }, screen
  });
}

export const duplicateDoc = (params, callback, screen) => {
  params.pageBy = 'orderFactor';
  return upsertItem({
    apiAddr: 'doc/duplicateDoc', params, successHandler: {
      isSilientAction: true,
      callback,
      navBack: false,
      action_types: [{ type: types.DOC_ACTIONS.updated }],
      actions: [(item) => {
        if (item.newSpace) {
          const SPACE_ACTIONS = {
            workspace: types.WORKSPACE_DOCS_ACTIONS,
            private: types.PRIVATE_DOCS_ACTIONS,
            shared: types.SHARED_DOCS_ACTIONS
          };

          return {
            type: SPACE_ACTIONS[item.newSpace].added,
            _id: item._id,
            item,
            params: { pageBy: 'orderFactor' },
            key: item.orgId
          };
        } else {
          return {
            type: types.SUB_DOCS_ACTIONS.added,
            _id: item._id,
            item,
            params: { pageBy: 'orderFactor' },
            key: item.parent
          };
        }
      }
      ]
    }, screen
  });
}

export const transferDoc = (params, callback, screen) => {
  let action_added = {
    type: types.SUB_DOCS_ACTIONS.added,
    list_key: params.to
  };
  if (params.to === 'root') {
    action_added = {
      list_key: params.orgId
    }
    if (params.toSpace === 'workspace') {
      action_added.type = types.WORKSPACE_DOCS_ACTIONS.added
    } else if (params.toSpace === 'private') {
      action_added.type = types.PRIVATE_DOCS_ACTIONS.added
    }
  }

  let action_removed = {
    type: types.SUB_DOCS_ACTIONS.deleted,
    list_key: params.from
  }
  if (params.from === 'root') {
    action_removed = {
      list_key: params.orgId
    }
    if (params.fromSpace === 'workspace') {
      action_removed.type = types.WORKSPACE_DOCS_ACTIONS.deleted;
    } else if (params.fromSpace === 'private') {
      action_removed.type = types.PRIVATE_DOCS_ACTIONS.deleted;
    } else if (params.fromSpace === 'shared') {
      action_removed.type = types.SHARED_DOCS_ACTIONS.deleted;
    }
  }

  params.pageBy = 'orderFactor';

  return upsertItem({ apiAddr: 'doc/transferDoc', params, successHandler: { isSilientAction: true, callback, action_types: [action_removed, action_added], navBack: true }, screen });
}

export const restoreDocFromHistory = (params, callback, screen) => {
  return upsertItem({
    apiAddr: 'doc/restoreDocFromHistory', params, successHandler: {
      actions: [(item) => {
        const SPACE_ACTIONS = {
          workspace: types.WORKSPACE_DOCS_ACTIONS,
          private: types.PRIVATE_DOCS_ACTIONS,
          shared: types.SHARED_DOCS_ACTIONS
        };

        return params.space ? {
          type: SPACE_ACTIONS[params.space].updated,
          _id: item._id,
          item,
          params: { pageBy: 'orderFactor' },
          key: item.orgId
        } : null;

      }, (item) => {
        return {
          type: types.SUB_DOCS_ACTIONS.updated,
          _id: item._id,
          item,
          params: { pageBy: 'orderFactor' },
          key: item.parent
        };
      }],
      callback,
      navBack: false
    }, screen
  });
}

export const docVisited = (params, callback, screen) => {
  return upsertItem({
    apiAddr: 'doc/visit', params, successHandler: {
      isSilientAction: true,
      callback,
      navBack: true
    }, screen
  });
}

export const upsertComment = (params, callback, screen) => {
  params.pageBy = 'createdAt';

  return upsertItem({
    apiAddr: 'doc/upsertComment', params, successHandler: {
      isSilientAction: true,
      callback,
      action_types: [{ type: types.COMMENTS_ACTIONS.added, list_key: params.data?.hid }],
      navBack: true
    }, screen
  });
}

export const deleteComment = (params, callback, screen) => {
  return upsertItem({
    apiAddr: 'doc/deleteComment', params, successHandler: {
      isSilientAction: true,
      callback,
      action_types: [{ type: types.COMMENTS_ACTIONS.deleted, list_key: params.data?.hid }],
      navBack: true
    }, screen
  });
}

export const createDbView = (params, callback, screen) => upsertItem({
  apiAddr: 'doc/createDbView',
  params,
  successHandler: {
    isSilientAction: true,
    action_types: [{ type: types.DB_VIEWS_ACTIONS.added_to_bottom, list_key: params.hid }],
    callback,
    navBack: true
  },
  screen
});

export const updateDbView = (params, callback, screen) => upsertItem({
  apiAddr: 'doc/updateDbView',
  params,
  successHandler: {
    isSilientAction: true,
    action_types: [{ type: types.DB_VIEWS_ACTIONS.updated, list_key: params.hid }],
    callback,
    navBack: true
  },
  screen
});

export const setViewDataSource = (params, callback, screen) => upsertItem({
  apiAddr: 'doc/setViewDataSource',
  params,
  successHandler: {
    isSilientAction: true,
    action_types: [{ type: types.DB_VIEWS_ACTIONS.updated, list_key: params.hid }],
    callback,
    navBack: true
  },
  screen
});

export const deleteDbView = (params, callback, screen) => upsertItem({
  apiAddr: 'doc/deleteDbView',
  params,
  successHandler: { action_types: [{ type: types.DB_VIEWS_ACTIONS.deleted, list_key: params.hid }], callback, navBack: true },
  screen
});

export const addDefaultStatusPropertyToDB = (params, callback, screen) => upsertItem({
  apiAddr: 'doc/addDefaultStatusPropertyToDB',
  params,
  successHandler: {
    isSilientAction: true,
    action_types: [{ type: types.DOC_ACTIONS.updated }],
    callback,
    navBack: true
  },
  screen
});

export const addDefaultDatePropertyToDB = (params, callback, screen) => upsertItem({
  apiAddr: 'doc/addDefaultDatePropertyToDB',
  params,
  successHandler: {
    isSilientAction: true,
    action_types: [{ type: types.DOC_ACTIONS.updated }],
    callback,
    navBack: true
  },
  screen
});

export const upsertViewDataOrder = (params, callback, screen) => upsertItem({
  apiAddr: 'doc/upsertViewDataOrder',
  params,
  successHandler: {
    isSilientAction: true,
    action_types: [{ type: types.DB_VIEW_DATA_ORDER_ACTIONS.added, list_key: params.viewId }],
    callback,
    navBack: true
  },
  screen
});

export const upsertViewDataHandler = (params, callback, screen, localOnly) => {
  if (localOnly) {
    return {
      type: types.VIEW_SORT_ACTIONS.updated,
      item: params.data
    }
  } else {

    // upsertItem({
    //   apiAddr: 'doc/upsertViewDataHandler',
    //   params,
    //   successHandler: { action_types: [{ type: types.DB_VIEW_DATA_HANDLERS_ACTIONS.added, list_key: params.viewId }], callback, navBack: true },
    //   screen
    // });
  }
}

export const updateDbData = (params, callback, screen) => {
  return upsertItem({
    apiAddr: 'doc/updateDbData', params, successHandler: {
      isSilientAction: true,
      action_types: [{ type: types.DBDATAS_ACTIONS.updated, list_key: params.hid }],
      callback,
      navBack: false
    }, screen
  });
}

export const deleteDbData = (params, callback, screen) => upsertItem({
  apiAddr: 'doc/updateDbData',
  params: {
    data: {
      _id: params._id,
      folder: 1
    }
  },
  successHandler: {
    isSilientAction: true,
    action_types: [{ type: types.DBDATAS_ACTIONS.deleted, list_key: params.hid }],
    callback,
    navBack: true
  },
  screen
});

export const newDbData = (params, callback, screen) => {
  return upsertItem({
    apiAddr: 'doc/newDbData', params, successHandler: {
      isSilientAction: true,
      action_types: [{ type: types.DBDATAS_ACTIONS.added, list_key: params.hid }],
      callback,
      navBack: false
    }, screen
  });
}

export const feedback = (params, successCallback, screen) => upsertItem({
  apiAddr: 'feedback/feedback', params, successHandler: {
    action_types: [{ type: types.FEEDBACKS_ACTIONS.added, list_key: types.LIST_KEYS.feedbacks }],
    callback: successCallback,
    navBack: true
  }, screen
});

export const grantPermission = (params, callback, screen) => upsertItem({
  apiAddr: 'doc/grantPermission', params, successHandler: {
    callback,
    navBack: false
  }, screen
});

export const grantPermissionTo = (params, callback, screen) => upsertItem({
  apiAddr: 'doc/grantPermissionTo', params, successHandler: {
    callback,
    navBack: false
  }, screen
});

export const newDoc = (params, callback, screen) => {
  const id = new Date().getTime() + 1;
  params = {
    data: {
      addedBlocks: [
        // { type: 'h1', id, children: [{ text: '' }] },
        { type: 'p', id: id, children: [{ text: '' }] }
      ],
      ...params.data,
      doc: { ...params.data.doc, blockIds: [id] },
    }
  };
  return upsertDoc(params, callback, false, screen);
}

export const deleteBlock = (params, callback, screen) => {
  return upsertItem({
    apiAddr: 'doc/deleteBlock',
    params,
    successHandler: {
      isSilientAction: true,
      action_types: [{ type: types.DOC_ACTIONS.updated }],
      callback,
      navBack: false
    },
    screen
  });
}

export const createEmbedDoc = (params, callback, screen) => {
  const id = new Date().getTime() + 1;
  params = {
    data: {
      addedBlocks: [
        { type: 'h1', id, children: [{ text: '' }] },
        { type: 'p', id: id + 1, children: [{ text: '' }] }
      ],
      ...params.data,
      doc: { ...params.data.doc, blockIds: [id, id + 1] },
    }
  };

  params.pageBy = 'orderFactor';
  return upsertItem({
    apiAddr: 'doc/createEmbedDoc', params, successHandler: {
      isSilientAction: true,
      callback,
      action_types: [{ type: types.SUB_DOCS_ACTIONS.updated, list_key: params.hid }],
      actions: [(item) => {
        if (!item.newChild) {
          return;
        }

        return {
          type: types.SUB_DOCS_ACTIONS.added,
          _id: item.newChild._id,
          item: item.newChild,
          params: { pageBy: 'orderFactor' },
          key: item.hid
        };
      }, (item) => {
        return {
          type: types.SUB_DOCS_ACTIONS.updated,
          _id: item._id,
          item,
          params: { pageBy: 'orderFactor' },
          key: item.parent,
        }
      }, (item) => {
        return {
          type: types.WORKSPACE_DOCS_ACTIONS.updated,
          _id: item._id,
          item,
          params: { pageBy: 'orderFactor' },
          key: item.orgId
        }
      }, (item) => {
        return {
          type: types.SHARED_DOCS_ACTIONS.updated,
          _id: item._id,
          item,
          params: { pageBy: 'orderFactor' },
          key: item.orgId,
        }
      }, (item) => {
        return {
          type: types.PRIVATE_DOCS_ACTIONS.updated,
          _id: item._id,
          item,
          params: { pageBy: 'orderFactor' },
          key: item.orgId
        }
      }],
      navBack: true
    }, screen
  });
}

export const trashDoc = (item, successCallback, screen) => upsertItem({
  apiAddr: 'doc/upsert',
  params: { data: { doc: { ...item, folder: Constants.DOCS_FOLDER.trashbin } } },
  successHandler: {
    isSilientAction: true,
    action_types: [
      { type: types.SUB_DOCS_ACTIONS.deleted, list_key: item.parent },
      { type: types.WORKSPACE_DOCS_ACTIONS.deleted, list_key: item.orgId },
      { type: types.PRIVATE_DOCS_ACTIONS.deleted, list_key: item.orgId },
      { type: types.SHARED_DOCS_ACTIONS.deleted, list_key: item.orgId },
      { type: types.FLOW_DOCS_ACTIONS.deleted, list_key: item.orgId },
      { type: types.DELETED_DOCS_ACTIONS.added, list_key: types.LIST_KEYS.doc },
      { type: types.DOC_ACTIONS.updated }
    ],
    callback: successCallback,
    navBack: true
  }, screen
});

export const restoreDoc = (item, successCallback, screen) => upsertItem({
  apiAddr: 'doc/restoreDoc',
  params: { data: { ...item } },
  successHandler: {
    navBack: true,
    action_types: [
      { type: types.DELETED_DOCS_ACTIONS.deleted, list_key: types.LIST_KEYS.doc },
      { type: types.DOC_ACTIONS.updated }
    ],
    callback: successCallback
  }, screen
});

export const deleteDoc = (item, successCallback, screen) => upsertItem({
  apiAddr: 'doc/deleteDoc',
  params: { data: { ...item } },
  successHandler: {
    navBack: true,
    action_types: [
      { type: types.DELETED_DOCS_ACTIONS.deleted, list_key: types.LIST_KEYS.doc },
    ],
    callback: successCallback
  }, screen
});

export const addSlides = (params, callback, screen) => {
  return upsertItem({ apiAddr: 'slides/upsert', params, successHandler: { callback, action_types: [{ type: types.SLIDES_ACTIONS.added, list_key: types.LIST_KEYS.slides }], navBack: true }, screen });
}

export const deleteSlides = (item, screen) => upsertItem({
  apiAddr: 'slides/upsert',
  params: { data: { ...item, folder: Constants.SLIDES_FOLDER.trashbin } },
  successHandler: {
    action_types: [{ type: types.SLIDES_ACTIONS.deleted, list_key: types.LIST_KEYS.slides },
    { type: types.DELETED_SLIDES_ACTIONS.added, list_key: types.LIST_KEYS.slides }],
    navBack: true
  }, screen
});

export const restoreSlides = (item, screen) => upsertItem({
  apiAddr: 'slides/upsert',
  params: { data: { ...item, folder: Constants.SLIDES_FOLDER.normal } },
  successHandler: {
    navBack: true,
    action_types: [{ type: types.SLIDES_ACTIONS.added, list_key: types.LIST_KEYS.slides },
    { type: types.DELETED_SLIDES_ACTIONS.deleted, list_key: types.LIST_KEYS.slides }]
  }, screen
});


export const addPost = (params, screen, dispatch) => {
  let callback = (item) => {
    let type = types.TICKET_ACTIONS.updated;
    dispatch({ type, item: item.ticket, key: item.ticketId });
  }

  return upsertItem({ apiAddr: 'ticket/addPost', params, successHandler: { callback, action_types: [{ type: types.POSTS_ACTIONS.added, list_key: types.LIST_KEYS.posts }], navBack: true }, screen });
}
export const deletePost = (params, screen) => upsertItem({
  apiAddr: 'ticket/deletePost', params, successHandler: {
    action_types: [{ type: types.POSTS_ACTIONS.deleted, list_key: types.LIST_KEYS.posts }],
    navBack: true
  }, screen
});
export const takeTicketAsTodo = (params, screen, dispatch) => {
  let callback = (item) => {
    let type = types.TODOS_ACTIONS.added;
    if (item.status === Constants.TODO_STATUS.doing) {
      type = types.DOINGS_ACTIONS.added;
    } else if (item.status === Constants.TODO_STATUS.done) {
      type = types.DONES_ACTIONS.added;
    }

    dispatch({ type, item, key: types.LIST_KEYS.todos });
  }

  return upsertItem({ apiAddr: 'ticket/takeTicketAsTodo', params, successHandler: { callback, navBack: true }, screen });
}

export const newTag = (params, successCallback, screen) => upsertItem({
  apiAddr: 'ticket/newTag', params, successHandler: {
    action_types: [{ type: types.TAGS_ACTIONS.added_to_bottom, list_key: types.LIST_KEYS.tags }],
    navBack: false,
    callback: successCallback
  }, screen
});
export const joinThread = (params, successCallback, screen) => upsertItem({
  apiAddr: 'message/joinThread', params, successHandler: {
    action_types: [{ type: types.THREADS_ACTIONS.added, list_key: types.LIST_KEYS.threads }],
    navBack: false,
    callback: successCallback
  }, screen
});
export const leaveThread = (params, successCallback, screen) => upsertItem({
  apiAddr: 'message/leaveThread', params, successHandler: {
    action_types: [{ type: types.THREADS_ACTIONS.deleted, list_key: types.LIST_KEYS.threads }],
    navBack: false, callback: successCallback
  }, screen
});
export const newMessage = (params, successCallback, list_key, screen) => upsertItem({
  apiAddr: 'message/newMessage', params, successHandler: {
    callback: successCallback,
    action_types: [{ type: types.MESSAGES_ACTIONS.added, list_key }], navBack: false
  }, screen
});
export const upsertPrompt = (params, successCallback, screen) => upsertItem({
  apiAddr: 'ai/upsertPrompt', params, successHandler: {
    callback: successCallback,
    action_types: [{ type: types.PROMPTS_ACTIONS.added, list_key: types.LIST_KEYS.tags }], navBack: false
  }, screen
});

export const validatePrompt = (params, successCallback, screen) => upsertItem({
  apiAddr: 'ai/upsertPrompt', params, successHandler: {
    callback: successCallback,
    action_types: [{ type: types.VALIDATE_PROMPTS_ACTIONS.deleted, list_key: types.LIST_KEYS.tags }], navBack: false
  }, screen
});

export const deletePrompt = (params, successCallback, screen) => upsertItem({
  apiAddr: 'ai/deletePrompt', params, successHandler: {
    callback: successCallback,
    action_types: [{ type: types.PROMPTS_ACTIONS.deleted, list_key: types.LIST_KEYS.tags }],
    navBack: false
  }, screen
});

export const pinPrompt = (params, successCallback, screen) => upsertItem({
  apiAddr: 'ai/pinPrompt', params, successHandler: {
    callback: successCallback,
    action_types: [{ type: params.pin ? types.PINNED_PROMPTS_ACTIONS.added : types.PINNED_PROMPTS_ACTIONS.deleted, list_key: types.LIST_KEYS.tags },
    { type: types.PUBLIC_PROMPTS_ACTIONS.updated, list_key: types.LIST_KEYS.tags }],
    navBack: false
  }, screen
});

export const addGroup = (params, callback, screen) => upsertItem({
  apiAddr: 'org/addGroup', params, successHandler: {
    action_types: [{ type: types.GROUPS_ACTIONS.added_to_bottom, list_key: params.orgId }],
    callback,
    navBack: true
  }, screen
});
export const addUsersToGroup = (params, callback, screen) => upsertItem({
  apiAddr: 'org/addUsersToGroup', params, successHandler: {
    action_types: [{ type: types.GROUPS_ACTIONS.updated, list_key: params.orgId }],
    callback,
    navBack: true
  }, screen
});
export const removeUserFromGroup = (params, callback, screen) => upsertItem({
  apiAddr: 'org/removeUserFromGroup', params, successHandler: {
    action_types: [{ type: types.GROUPS_ACTIONS.updated, list_key: params.orgId }],
    callback,
    navBack: true
  }, screen
});

export const addOrg = (params, callback, screen) => upsertItem({
  apiAddr: 'org/addOrg', params, successHandler: {
    callback,
    action_types: [{ type: types.ORGS_ACTIONS.added_to_bottom, list_key: types.LIST_KEYS.orgs }],
    navBack: true
  }, screen
});

export const updateOrg = (params, callback, screen) => upsertItem({
  apiAddr: 'org/updateOrg', params, successHandler: {
    callback,
    action_types: [{ type: types.ORGS_ACTIONS.updated, list_key: types.LIST_KEYS.orgs }],
    navBack: true
  }, screen
});

export const updateUserRoleToOrg = (params, callback, screen) => upsertItem({
  apiAddr: 'org/updateUserRoleToOrg', params, successHandler: {
    callback,
    action_types: [{
      type: types.ORGS_ACTIONS.updated,
      list_key: types.LIST_KEYS.orgs
    }],
    navBack: true
  }, screen
});

export const confirmJoinOrg = (params, callback, screen) => {
  const action_types = [{
    type: types.TO_CONFIRM_ORGS_ACTIONS.deleted, list_key: types.LIST_KEYS.orgs
  }];

  if (params.confirmed) {
    action_types.push({ type: types.ORGS_ACTIONS.added, list_key: types.LIST_KEYS.orgs });
  }

  return upsertItem({
    apiAddr: 'org/confirmJoinOrg', params, successHandler: {
      callback,
      action_types,
      navBack: true
    }, screen
  });
};

export const inviteUserToGroup = (params, callback, screen) => upsertItem({
  apiAddr: 'org/inviteUserToGroup', params, successHandler: {
    callback,
    action_types: [{ type: types.GROUP_MEMBERS_ACTIONS.added_to_bottom, list_key: params.groupId }],
    navBack: false
  }, screen
});

export const inviteUsersToOrg = (params, callback, screen) => upsertItem({
  apiAddr: 'org/inviteUsersToOrg', params, successHandler: {
    callback,
    action_types: [{ type: types.ORGS_ACTIONS.updated, list_key: types.LIST_KEYS.orgs }],
    navBack: false
  }, screen
});

const logon = (user, params, callback) => {
  if (callback) {
    callback(user);
  }
}

export const login = (params, callback, failureCallback, screen) => {
  return upsertItem({
    apiAddr: 'users/login',
    params,
    successHandler: {
      action_types: [{ type: types.LOGIN_ACTIONS.in }],
      navBack: false,
      callback: (user) => logon(user, params, callback)
    },
    failureCallback,
    screen
  });
}

export const oauth_sign_in = (params, callback, failureCallback, screen) => {
  return upsertItem({
    apiAddr: 'users/oauth-sign-in',
    params,
    successHandler: {
      action_types: [{ type: types.LOGIN_ACTIONS.in }],
      navBack: false,
      callback: (user) => logon(user, params, callback)
    },
    failureCallback,
    screen
  });
}

export const oauth_sign_in_credential = (params, callback, failureCallback, screen) => {
  return upsertItem({
    apiAddr: 'users/oauth-sign-in-credential',
    params,
    successHandler: {
      action_types: [{ type: types.LOGIN_ACTIONS.in }],
      navBack: false,
      callback: (user) => logon(user, params, callback)
    },
    failureCallback,
    screen
  });
}

export const oauth = (params, callback, failureCallback, screen) => {
  return upsertItem({
    apiAddr: 'users/oauth',
    params,
    successHandler: {
      action_types: [{ type: types.LOGIN_ACTIONS.in }],
      navBack: false,
      callback: (user) => logon(user, params, callback)
    },
    failureCallback,
    screen
  });
}

export const logout_of_server = (params, screen) => {
  return upsertItem({ apiAddr: 'users/logout', params, successHandler: { navBack: true, action_types: [{ type: types.LOGIN_ACTIONS.out }] }, screen });
}

export const logout_of_app = () => {
  clearAccessCookie();
  return { type: types.LOGIN_ACTIONS.out };
}

export const register = (params, successCallback, screen) => upsertItem({
  apiAddr: 'users/register', params, successHandler: {
    action_types: [{ type: types.LOGIN_ACTIONS.in }],
    callback: (user) => logon(user, params, successCallback),
    navBack: false
  }, screen
});

export const updateUserName = (params, successCallback, screen) => upsertItem({
  apiAddr: 'users/updateUserName', params, successHandler: {
    action_types: [{ type: types.LOGIN_ACTIONS.in }],
    callback: successCallback,
    navBack: false
  }, screen
});

export const updateRILBindedOrgId = (params, successCallback, screen) => upsertItem({
  apiAddr: 'users/updateRILBindedOrgId', params, successHandler: {
    action_types: [{ type: types.LOGIN_ACTIONS.in }],
    callback: successCallback,
    navBack: false
  }, screen
});


export const resetPassword = (params, successCallback, screen) => upsertItem({
  apiAddr: 'users/resetpwd', params, successHandler: {
    callback: successCallback,
    navBack: false
  }, screen
});
export const bindEmail = (params, successCallback, screen) => upsertItem({
  apiAddr: 'users/bindEmail', params, successHandler: {
    action_types: [{ type: types.LOGIN_ACTIONS.in }],
    callback: successCallback,
    navBack: false
  }, screen
});
export const resendActivationEmail = (params, successCallback, screen) => upsertItem({
  apiAddr: 'users/resendVerificationEmail', params, successHandler: {
    action_types: [{ type: types.LOGIN_ACTIONS.in }],
    callback: successCallback,
    navBack: false
  }, screen
});
export const bindPushId = (params, screen) => upsertItem({ apiAddr: 'users/bindPushId', params, successHandler: { navBack: false }, screen });

export const authReceipt = (params, screen) => upsertItem({
  apiAddr: 'payment/authReceipt', params, successHandler: {
    action_types: [{ type: types.LOGIN_ACTIONS.in }],
    navBack: false
  }, screen
});

export const initPayment = (params, callback, screen) => upsertItem({
  apiAddr: 'payment/initPayment', params, successHandler: {
    callback,
    navBack: false
  }, screen
});

export const createConfirmSubscription = (params, callback, failureCallback, screen) => upsertItem({
  apiAddr: 'payment/createConfirmSubscription', params, successHandler: {
    callback,
    navBack: false
  }, failureCallback, screen
});

export const applyPromoCode = (params, callback, failureCallback, screen) => upsertItem({
  apiAddr: 'payment/applyPromoCode', params, successHandler: {
    callback,
    navBack: false
  }, failureCallback, screen
});

export const initSubscription = (params, callback, failureCallback, screen) => upsertItem({
  apiAddr: 'payment/initSubscription', params, successHandler: {
    callback,
    navBack: false
  }, failureCallback, screen
});
export const cancelSubscription = (params, callback, failureCallback, screen) => upsertItem({
  apiAddr: 'payment/cancelSubscription', params, successHandler: {
    callback,
    navBack: false
  }, failureCallback, screen
});

export const stripePaid = (params, callback, failureCallback, screen) => upsertItem({
  apiAddr: 'payment/stripePaid', params, successHandler: {
    callback,
    navBack: false
  }, failureCallback, screen
});

export const switchWorkspace = (params, callback, screen) => upsertItem({
  apiAddr: 'users/switchWorkspace', params, successHandler: {
    action_types: [{ type: types.LOGIN_ACTIONS.workspace }],
    callback,
    navBack: false
  }, screen
});

export const lngSetting = (params, callback, screen) => upsertItem({
  apiAddr: 'users/lngSetting', params, successHandler: {
    callback,
    actions: [(item) => {
      return { type: types.LNG_SETTING, value: item }
    }],
    navBack: false
  }, screen
});

export const saveUninstallReason = (params, callback, screen) => upsertItem({
  apiAddr: 'info/saveUninstallReason', params, successHandler: {
    callback,
    navBack: false
  }, screen
});

function genKeyByUser(getState, key) {
  if (key) {
    return key;
  }

  let { loginIn } = getState();

  let userId = '';
  if (loginIn && loginIn.user) {
    userId = loginIn.user._id || '';
  }

  return userId;
}

function upsertItem({ apiAddr, params, successHandler, failureCallback, screen }) {
  return async function (dispatch, getState) {

    dispatch({ type: types.OPERATION_IN_PROGRESS, isSilientAction: true, screen });

    let result = null;
    let errMessage = 'operation failed';

    try {
      let response = await funblocks_api_fetch(apiAddr, 'POST', params);
      if (response.status === 403) {
        console.log('user unauthorized');
        dispatch(logout_of_app());
        throw 'Unauthenticated user';
      } else if (response.status === 400) {
        throw 'Wrong params';
      }

      result = await response.json();
    } catch (error) {
      console.log('error msg', error);
      // if (error.message == 'TIMEOUT') {
      //   dispatch({ type: types.OPERATION_FAILED });
      // }
      errMessage = error.message;
    }

    if (result && result.success == 1) {
      if (result.data) {
        let item = result.data;

        if (successHandler.action_types) {
          successHandler.action_types.forEach(action_type => {
            dispatch(upsertedItemAction(action_type.type, item, params, genKeyByUser(getState, action_type.list_key)));
          });
        }

        if (successHandler.actions) {
          successHandler.actions.forEach(action => {
            const actionObj = action(item);
            !!actionObj && dispatch(actionObj);
          });
        }
      }

      if (successHandler.callback) {
        successHandler.callback(result.data);
      }

      dispatch({
        type: types.OPERATION_SUCCESS,
        message: !successHandler.isSilientAction && (successHandler.message || result.message),
        navBack: !successHandler.isSilientAction && successHandler.navBack,
        screen
      });
      return;
    }

    if (result?.code == 555) {
      dispatch({
        type: types.PROMOTE_VIP,
        value: {
          visible: true
        }
      });

      dispatch({
        type: types.OPERATION_SUCCESS,
        screen
      });

      return;
    }

    if (result && result.success == 0) {
      errMessage = result.message;
    }

    if (failureCallback) {
      failureCallback(errMessage);
    }

    dispatch({ type: types.OPERATION_FAILED, message: !successHandler.isSilientAction && errMessage });
  }
}

export const getAIModels = (ai_provider, endpoint, api_token, successCallback, failureCallback, screen) => {
  return async function (dispatch, getState) {

    dispatch({ type: types.OPERATION_IN_PROGRESS, isSilientAction: true, screen });

    let url = endpoint + '/models';
    let headers = {};
    let params = {};

    if (ai_provider == 'gemini') {
      // url = 'https://generativelanguage.googleapis.com/v1beta/models';
      params = { key: api_token }
    } else {
      // url = 'https://api.openai.com/v1/models';
      headers = { Authorization: 'Bearer ' + api_token };
    }

    let result = null;
    let errMessage = 'operation failed';
    try {
      let response = await api_fetch(url, 'GET', headers, 'cors', params, 100000);
      // let response = await api_fetch(`https://funblocks-ai.openai.azure.com/openai/deployments/fbgpt35/chat/completions?api-version=2023-03-15-preview`, 'POST', { 'api-key': api_token }, params, 100000);

      result = await response.json();
    } catch (error) {
      console.log('error msg', error);
      errMessage = error.message;
    }

    if (result?.data || result?.models) {
      let item = result?.models || result?.data;


      if (successCallback) {
        successCallback(item);
      }

      dispatch({
        type: types.OPERATION_SUCCESS,
        screen
      });
      return;
    }

    if (result?.error) {
      errMessage = result.error.message;
    }

    if (failureCallback) {
      failureCallback(errMessage);
    }

    dispatch({ type: types.OPERATION_FAILED });
  }
}

export const callOpenAI = (endpoint, api_token, params, successCallback, failureCallback, screen) => {
  return aiClient({
    address: `${endpoint || 'https://api.openai.com/v1'}/chat/completions`,
    headers: endpoint?.includes('.azure.') ? { "api-key": api_token } : { Authorization: 'Bearer ' + api_token },
    mode: 'cors',
    params,
    successHandler: {
      callback: successCallback && ((result) => {
        if (result.choices?.length > 0) {
          if (result.choices[0].message?.content) {
            return successCallback(result.choices[0].message?.content)
          }

          if (result.choices[0]?.finish_reason) {
            return failureCallback(result.choices[0].finish_reason === 'content_filter' ? 'prompt_blocked_safety' : result.choices[0].finish_reason);
          }
        }

        if (result.error) {
          return failureCallback(result.error.message)
        }

        return failureCallback('unknown_failure')
      }),
      isSilientAction: false,
      navBack: false
    },
    failureCallback,
    screen
  });
}

export const callAnthropic = (endpoint, api_token, params, successCallback, failureCallback, screen) => {
  return aiClient({
    // address: `${endpoint || 'https://api.anthropic.com/v1'}/complete`,
    address: endpoint == 'https://api.anthropic.com/v1' ? '/anthropic/v1/messages' : `${endpoint || 'https://api.anthropic.com/v1'}/messages`,
    headers: {
      accept: 'application/json',
      "content-type": 'application/json',
      "anthropic-version": "2023-06-01",
      'x-api-key': api_token
    },
    // mode: 'no-cors',
    mode: 'cors',
    params,
    successHandler: {
      callback: successCallback && ((result) => {
        if (result.completion) {
          return successCallback(result.completion)
        }

        return failureCallback('unknown_failure')
      }),
      isSilientAction: false,
      navBack: false
    },
    failureCallback,
    screen
  });
}

export const callPaLM = (endpoint, api_token, params, successCallback, failureCallback, screen) => {
  const url = `${endpoint || 'https://generativelanguage.googleapis.com/v1beta'}/${params.model}:generateContent?key=${api_token}`;
  delete params.model
  return aiClient({
    address: url,
    headers: {
      "content-type": 'application/json',
    },
    mode: 'cors',
    params,
    successHandler: {
      callback: successCallback && ((result) => {
        if (result.candidates?.length > 0) {
          if (result.candidates[0].content?.parts[0]?.text) {
            return successCallback(result.candidates[0].content.parts[0].text);
          }
          if (result.candidates[0].finishReason) {
            return failureCallback(result.candidates[0].finishReason);
          }
        }

        if (result.promptFeedback?.blockReason) {
          return failureCallback('prompt_blocked_safety');
        }

        return failureCallback('unknown_failure')
      }),
      isSilientAction: false,
      navBack: false
    },
    failureCallback,
    screen
  });
}

function aiClient({ address, headers, mode, params, successHandler, failureCallback, screen }) {
  return async function (dispatch, getState) {

    dispatch({ type: types.OPERATION_IN_PROGRESS, isSilientAction: true, screen });


    let result = null;
    let errMessage = 'operation failed';
    try {
      let response = await api_fetch(address, 'POST', headers, mode, params, 100000);

      result = await response.json();
    } catch (error) {
      console.log('error msg', error);
      errMessage = error.message;
    }

    console.log('result.......', result)

    if (result && !result.error) {

      if (successHandler.callback) {
        successHandler.callback(result);
      }

      dispatch({
        type: types.OPERATION_SUCCESS,
        message: !successHandler.isSilientAction && (successHandler.message || result.message),
        navBack: !successHandler.isSilientAction && successHandler.navBack,
        screen
      });
      return;
    }

    if (result?.error) {
      errMessage = result.error.message;
    }


    if (failureCallback) {
      failureCallback(errMessage);
    }

    dispatch({ type: types.OPERATION_FAILED, message: !successHandler.isSilientAction && errMessage });
  }
}

export const fetchTodos = (params) => {
  params = Object.assign({ status: Constants.TODO_STATUS.todo }, params);
  return fetchList('ticket/todoList', params, types.LIST_KEYS.todos, types.TODOS_ACTIONS);
}
export const fetchDoings = (params, successCallback) => {
  params = Object.assign({ status: Constants.TODO_STATUS.doing }, params);
  return fetchList('ticket/todoList', params, types.LIST_KEYS.todos, types.DOINGS_ACTIONS, successCallback);
}
export const fetchDones = (params) => {
  params = Object.assign({ status: Constants.TODO_STATUS.done }, params);
  return fetchList('ticket/todoList', params, types.LIST_KEYS.todos, types.DONES_ACTIONS);
}
export const fetchDeletedNotes = (params) => {
  params = Object.assign({ folder: Constants.TODO_FOLDER.trashbin }, params);
  return fetchList('ticket/todoList', params, types.LIST_KEYS.todos, types.DELETED_TODOS_ACTIONS);
}

export const fetchDocs = (params, callback) => {
  return fetchList('doc/docList', params, params.orgId, types.DOCS_ACTIONS, callback);
}

export const fetchFlowDocs = (params, callback) => {
  params.type = 'flow';
  return fetchList('doc/docList', params, params.orgId, types.FLOW_DOCS_ACTIONS, callback);
}

export const fetchDbData = (params) => {
  return fetchList('doc/getDbData', params, params.hid, types.DBDATAS_ACTIONS);
}

export const fetchDbViews = (params) => {
  return fetchList('doc/getDbViews', params, params.hid, types.DB_VIEWS_ACTIONS);
}

export const fetchDbViewDataOrder = (params) => {
  return fetchList('doc/getDbViewDataOrder', params, params.hid, types.DB_VIEW_DATA_ORDER_ACTIONS);
}

export const fetchWorkSpaceDocs = (params) => {
  params.space = 'workspace';
  return fetchList('doc/getSpaceRootDocList', params, params.orgId, types.WORKSPACE_DOCS_ACTIONS);
}
export const fetchSharedDocs = (params) => {
  params.space = 'shared';
  return fetchList('doc/getSpaceRootDocList', params, params.orgId, types.SHARED_DOCS_ACTIONS);
}
export const fetchPrivateDocs = (params) => {
  params.space = 'private';
  return fetchList('doc/getSpaceRootDocList', params, params.orgId, types.PRIVATE_DOCS_ACTIONS);
}

export const fetchDeletedDocs = (params) => {
  params = Object.assign({ folder: Constants.DOCS_FOLDER.trashbin }, params);
  return fetchList('doc/docList', params, params.orgId, types.DELETED_DOCS_ACTIONS);
}

export const fetchSubDocs = (params, list_key) => fetchList('doc/docList', params, list_key, types.SUB_DOCS_ACTIONS);
export const fetchDocHistories = (params, list_key) => fetchList('doc/getDocHistories', params, list_key, types.DOC_HISTORIES_ACTIONS);

export const fetchComments = (params, callback) => {
  return fetchList('doc/getComments', params, params.hid, types.COMMENTS_ACTIONS, callback);
}

export const fetchSlides = (params, callback) => {
  return fetchList('slides/slidesList', params, types.LIST_KEYS.slides, types.SLIDES_ACTIONS, callback);
}
export const fetchDeletedSlides = (params) => {
  params = Object.assign({ folder: Constants.SLIDES_FOLDER.trashbin }, params);
  return fetchList('slides/slidesList', params, types.LIST_KEYS.slides, types.DELETED_SLIDES_ACTIONS);
}

export const fetchRILs = (params, callback) => {
  params = Object.assign({ folder: Constants.RIL_FOLDER.ril }, params);
  return fetchList('article/rilList', params, types.LIST_KEYS.rils, types.RILS_ACTIONS, callback);
}
export const fetchRILsRead = (params) => {
  params = Object.assign({ folder: Constants.RIL_FOLDER.read }, params);
  return fetchList('article/rilList', params, types.LIST_KEYS.rils, types.READ_RILS_ACTIONS);
}
export const fetchRILsDeleted = (params) => {
  params = Object.assign({ folder: Constants.RIL_FOLDER.trashbin }, params);
  return fetchList('article/rilList', params, types.LIST_KEYS.rils, types.DELETED_RILS_ACTIONS);
}
export const searchRILs = (params) => fetchList('article/search', params, types.LIST_KEYS.rils, types.SEARCHED_RILS_ACTIONS);
export const searchTodos = (params) => fetchList('ticket/search', params, types.LIST_KEYS.todos, types.SEARCHED_TODOS_ACTIONS);

export const fetchPrompts = (params, callback) => fetchList('ai/prompts', params, types.LIST_KEYS.tags, types.PROMPTS_ACTIONS, callback);
export const fetchPromptsPublic = (params) => {
  params.qualified = 1;
  return fetchList('ai/public_prompts', params, types.LIST_KEYS.tags, types.PUBLIC_PROMPTS_ACTIONS);
}
export const fetchPromptsWorkspace = (params) => fetchList('ai/workspace_prompts', params, types.LIST_KEYS.tags, types.WORKSPACE_PROMPTS_ACTIONS);
export const fetchPromptsPinned = (params) => fetchList('ai/pinned_prompts', params, types.LIST_KEYS.tags, types.PINNED_PROMPTS_ACTIONS);
export const fetchPromptsValidate = (params) => {
  params.qualified = 0;
  return fetchList('ai/public_prompts', params, types.LIST_KEYS.tags, types.VALIDATE_PROMPTS_ACTIONS);
}
export const fetchTags = (params) => fetchList('ticket/tagList', params, params.app, types.TAGS_ACTIONS);
export const fetchPosts = (params) => fetchList('ticket/postList', params, types.LIST_KEYS.posts, types.POSTS_ACTIONS);
export const fetchOKRs = (params) => fetchList('ticket/okrList', params, types.LIST_KEYS.okrs, types.OKRS_ACTIONS);
export const fetchAlignedOKRs = (params, list_key) => fetchList('ticket/okrList', params, list_key, types.ALIGNED_OKRS_ACTIONS);
export const fetchGroupOKRs = (params) => fetchList('ticket/groupOkrList', params, types.LIST_KEYS.okrs, types.GROUP_OKRS_ACTIONS);
export const fetchCompanyOKRs = (params) => fetchList('ticket/okrList', params, types.LIST_KEYS.okrs, types.COMPANY_OKRS_ACTIONS);
export const fetchPeopleOKRs = (params) => fetchList('ticket/okrList', params, types.LIST_KEYS.okrs, types.PEOPLE_OKRS_ACTIONS);
export const fetchMyKeyResults = (params, list_key) => fetchList('ticket/objectiveKeyResultList', params, list_key, types.MY_KRS_ACTIONS);
export const fetchCompanyKeyResults = (params, list_key) => fetchList('ticket/objectiveKeyResultList', params, list_key, types.COMPANY_KRS_ACTIONS);
export const fetchGroupKeyResults = (params, list_key) => fetchList('ticket/groupObjKeyResultList', params, list_key, types.GROUP_KRS_ACTIONS);
export const fetchPeopleKeyResults = (params, list_key) => fetchList('ticket/peopleObjKeyResultList', params, list_key, types.PEOPLE_KRS_ACTIONS);
export const fetchKeyResults = (params, list_key) => fetchList('ticket/keyResultList', params, list_key, types.KEY_RESULTS_ACTIONS);
export const fetchThreads = (params) => fetchList('message/threads', params, types.LIST_KEYS.threads, types.THREADS_ACTIONS);
export const fetchThreadFollowStatus = (params) => fetchList('message/thread_follow_status', params, types.LIST_KEYS.threads, types.THREADS_ACTIONS);
export const fetchThreadMembers = (params) => fetchList('message/thread_member_list', params, params.threadId, types.THREAD_MEMBERS_ACTIONS);
export const fetchMessages = (params, list_key, successCallback) => fetchList('message/messages', params, list_key, types.MESSAGES_ACTIONS, successCallback);
export const fetchSubTickets = (params, list_key) => fetchList('ticket/subTickets', params, list_key, types.SUB_TICKETS_ACTIONS);
export const fetchGroupTickets = (params, list_key) => fetchList('ticket/groupTickets', params, list_key, types.GROUP_TICKETS_ACTIONS);
export const fetchGroupTodos = (params, list_key) => {
  params = Object.assign({ status: Constants.TODO_STATUS.todo }, params);
  return fetchList('ticket/groupTodos', params, list_key, types.GROUP_TODOS_ACTIONS);
}

export const fetchGroupDoings = (params, list_key) => {
  params = Object.assign({ status: Constants.TODO_STATUS.doing }, params);
  return fetchList('ticket/groupTodos', params, list_key, types.GROUP_DOINGS_ACTIONS);
}

export const fetchGroupDones = (params, list_key) => {
  params = Object.assign({ status: Constants.TODO_STATUS.done }, params);
  return fetchList('ticket/groupTodos', params, list_key, types.GROUP_DONES_ACTIONS);
}

export const fetchOrgs = (successCallback) => fetchList('org/orgList', { pageNum: 0, pageSize: 0 }, types.LIST_KEYS.orgs, types.ORGS_ACTIONS, successCallback);
export const fetchToComfirmOrgs = (successCallback) => fetchList('org/orgToConfirmList', { pageNum: 0, pageSize: 0 }, types.LIST_KEYS.orgs, types.TO_CONFIRM_ORGS_ACTIONS, successCallback);
export const fetchGroups = (params) => fetchList('org/groupList', params, params.orgId, types.GROUPS_ACTIONS);
export const fetchGroupMembers = (groupId) => fetchList('org/memberList', { pageNum: 0, pageSize: 0, groupId }, groupId, types.GROUP_MEMBERS_ACTIONS);

export const fetchArticleHighlights = (articleId, successCallback) => fetchList('article/articleHighlights', { pageNum: 0, pageSize: 0, articleId }, articleId, types.ARTICLE_HIGHLIGHTS_ACTIONS, successCallback);

export const fetchFeedbacks = (params) => {
  return fetchList('feedback/feedbacks', params, types.LIST_KEYS.feedbacks, types.FEEDBACKS_ACTIONS);
}

function fetchList(apiAddr, params, list_key, actionTypes, successCallback) {
  return async function (dispatch, getState) {
    let key = genKeyByUser(getState, list_key);
    dispatch(requestListAction(actionTypes.requesting, key));

    let result = null;
    let errMessage = 'operation failed';
    try {
      // console.log('will request list items', params);
      let response = await funblocks_api_fetch(apiAddr, 'GET', params);
      if (response.status === 403) {
        console.log('user unauthorized');
        dispatch(logout_of_app());
        throw 'Unauthenticated user';
      } else if (response.status === 400) {
        throw 'Wrong params';
      }

      result = await response.json();
    } catch (error) {
      console.log('error msg', error);
      errMessage = error.message;
    }

    if (result && result.success == 1) {
      let items = result.data || [];

      dispatch({
        items,
        params,
        receivedAt: Date.now(),
        type: actionTypes.received,
        key
      });

      if (params.pageTo && result.load_more) {
        params.pageFrom = items[items.length - 1][params.pageBy];
        dispatch(fetchList(apiAddr, params, key, actionTypes));
        return;
      }

      if (successCallback) {
        successCallback(items);
      }

      return;
    }

    if (result?.code == 555) {
      dispatch({
        type: types.PROMOTE_VIP,
        value: {
          visible: true
        }
      });

      dispatch({
        type: types.OPERATION_SUCCESS
      });

      return;
    }

    if (result && result.success == 0) {
      errMessage = result.message;
    }
    dispatch({ type: types.OPERATION_FAILED, message: errMessage });
  }
}

export const invalidateTicketComments = (list_key) => invalidateLocalData(list_key, types.MESSAGES_ACTIONS);
export const invalidateSubTickets = (list_key) => invalidateLocalData(list_key, types.SUB_TICKETS_ACTIONS);


function shouldRequestList(state_list, forceRefresh) {
  if (isFetching(state_list)) {
    return false;
  } else if (!state_list || !state_list.items || state_list.items.length == 0) {
    return true;
  } else {
    return forceRefresh || state_list.didInvalidate;
  }
}

export const refreshList = (state_list, fetchListAction, params, list_key, invalidateList = false) => {
  return (dispatch) => {
    params = Object.assign({ pageNum: 0, pageSize: init_list_state.pageSize }, params);
    if (params.pageNum || params.pageFrom || shouldRequestList(state_list, invalidateList)) {
      return dispatch(fetchListAction(params, list_key));
    }
  }
}

export const getAppConfig = (params, callback, screen) => {
  return fetchData({ apiAddr: 'users/appConfigs', params, successHandler: { callback }, screen });
}

export const getSlidesConfig = (params, callback, screen) => {
  return fetchData({ apiAddr: 'slides/getSlidesConfig', params, successHandler: { callback }, screen });
}

export const getLatestAppVersion = (params, callback, screen) => {
  return fetchData({ apiAddr: 'info/latestAppVersion', params, successHandler: { callback }, screen });
}

export const getStrLng = (params, callback, screen) => {
  return fetchData({ apiAddr: 'info/getLanguage', params, successHandler: { callback }, screen });
}

export const getLngList = (params, callback, screen) => {
  return fetchData({ apiAddr: 'info/getLanguageList', params, successHandler: { action_type: types.LNG_LIST, callback }, screen });
}

export const getInviteFriendMsg = (params, callback, screen) => fetchData({ apiAddr: 'users/inviteFriendMsg', params, successHandler: { callback }, screen });
export const getInviteCode = (params, callback, screen) => fetchData({ apiAddr: 'users/getInviteCode', params, successHandler: { callback }, screen });

export const getAICoinsProducts = (params, successCallback, screen) => fetchData({ apiAddr: 'payment/getAICoinsProducts', params, successHandler: { action_type: types.AI_COINS_PRODUCTS, callback: successCallback }, screen });
export const getAvailableProducts = (params, successCallback, screen) => fetchData({ apiAddr: 'payment/getAvailableProducts', params, successHandler: { action_type: types.AVAILABLE_PRODUCTS, callback: successCallback }, screen });
export const getServingProducts = (params, successCallback, screen) => fetchData({ apiAddr: 'users/servingProducts', params, successHandler: { action_type: types.SERVING_PRODUCTS, callback: successCallback }, screen });
export const checkPaymentStatus = (params, successCallback, screen) => fetchData({ apiAddr: 'payment/checkPaymentStatus', params, successHandler: { action_type: types.SERVING_PRODUCT, callback: successCallback }, screen });
export const getAICoinBalanceAndTrans = (params, successCallback, screen) => fetchData({ apiAddr: 'payment/getAICoinBalanceAndTrans', params, successHandler: { action_type: types.AI_TOKEN_BALANCE_TRANS, callback: successCallback }, screen });

export const getDocIgnoreContent = (params, callback, screen) => fetchData({ apiAddr: 'doc/getDocIgnoreContent', params, successHandler: { callback }, screen });
export const getDoc = (params, callback, failureCallback, screen) => fetchData({ apiAddr: 'doc/getDoc', params, failureCallback, successHandler: { action_type: types.DOC_ACTIONS.received, callback }, screen });
export const exportDoc = (params, callback, failureCallback, screen) => fetchData({ apiAddr: 'doc/exportDoc', params, failureCallback, successHandler: { callback }, screen, timeout: 120000 });
export const exportSlides = (params, callback, failureCallback, screen) => fetchData({ apiAddr: 'doc/exportSlides', params, failureCallback, successHandler: { callback }, screen, timeout: 120000 });

export const getDBView = (params, callback, screen) => fetchData({ apiAddr: 'doc/getDbView', params, successHandler: { action_type: types.DB_VIEW_ACTIONS.received, callback }, screen });
export const getDocAsMarkdown = (params, screen) => fetchData({ apiAddr: 'doc/getDocAsMarkdown', params, successHandler: { action_type: types.DOC_HTML_ACTIONS.received }, screen });
export const getDbDocWithData = (params, callback, screen) => fetchData({ apiAddr: 'doc/getDbDocWithData', params, successHandler: { action_type: types.DOC_ACTIONS.received, callback }, screen });
export const getHistoryDoc = (params, callback, screen) => fetchData({ apiAddr: 'doc/getHistoryDoc', params, successHandler: { action_type: types.HISTORY_DOC_ACTIONS.received, callback }, screen });
export const getSlides = (params, callback, screen) => fetchData({ apiAddr: 'slides/getSlides', params, successHandler: { action_type: types.SLIDES_ACTIONS.updated, callback }, screen });
export const getRIL = (params, callback, screen) => fetchData({ apiAddr: 'article/getRIL', params, successHandler: { action_type: types.RILS_ACTIONS.updated, callback }, screen });
export const getTicket = (params, callback, screen) => fetchData({ apiAddr: 'ticket/ticket', params, successHandler: { action_type: types.TICKET_ACTIONS.received, callback }, screen });
export const getOKR = (params, callback, screen) => fetchData({ apiAddr: 'ticket/okr', params, successHandler: { action_type: types.OKR_ACTIONS.received, callback }, screen });
export const searchUser = (params, callback, screen) => fetchData({ apiAddr: 'ticket/searchUser', params, successHandler: { callback }, screen });
export const sendVerificationCode = (params, callback, screen) => fetchData({ apiAddr: 'users/verify', params, successHandler: { callback }, screen });
export const verifyCaptcha = (params, callback, screen) => fetchData({ apiAddr: 'users/verifyCaptcha', params, successHandler: { callback }, screen });
export const getInvitation = (params, callback, screen) => fetchData({ apiAddr: 'users/invitation', params, successHandler: { callback }, screen });
export const getUserInfo = (params, screen) => fetchData({ apiAddr: 'users/info', params, successHandler: { action_type: types.LOGIN_ACTIONS.in }, screen });
export const getArticleTitle = (params, successCallback, failureCallback, screen) => fetchData({ apiAddr: 'article/getTitle', params, successHandler: { callback: successCallback }, failureCallback, screen });
export const getArticleText = (params, successCallback, failureCallback, screen) => fetchData({ apiAddr: 'article/getText', params, successHandler: { callback: successCallback }, failureCallback, screen });
export const getGatherRule = (params, callback, screen) => fetchData({ apiAddr: 'article/getGatherRule', params, successHandler: { callback }, screen });
export const getSlidesTempEditKey = (params, successCallback, failureCallback, screen) => fetchData({ apiAddr: 'slides/getTempEditKey', params, successHandler: { callback: successCallback }, failureCallback, screen });

export const getPrompt = (params, callback, failureCallback, screen) => fetchData({ apiAddr: 'ai/prompt', params, failureCallback, successHandler: { action_type: types.PROMPT_ACTIONS.received, callback }, screen });

export const getServingPrivilege = (params, successCallback, screen) => fetchData({ apiAddr: 'payment/getServingPrivilege', params, successHandler: { callback: successCallback }, screen });

export const getFeedbackTypeOptions = (params, successCallback, screen) => fetchData({ apiAddr: 'feedback/feedbackTypeOptions', params, successHandler: { callback: successCallback, isSilientAction: true }, screen });
export const getUninstallReasons = (params, successCallback, screen) => fetchData({ apiAddr: 'info/getUninstallReasons', params, successHandler: { callback: successCallback, isSilientAction: true }, screen });

export const getLngSetting = (params, callback, screen) => fetchData({
  apiAddr: 'users/getLngSetting', params, successHandler: {
    callback,
    actions: [(item) => {
      return { type: types.LNG_SETTING, value: item }
    }],
  }, screen
});

export const getInviteRecord = (params, callback) => {
  return fetchData({ apiAddr: 'users/inviteRecords', params, successHandler: { callback } });
}

function fetchData({ apiAddr, params, successHandler, failureCallback, screen, timeout }) {
  return async function (dispatch, getState) {
    // console.log('will request data', params);

    dispatch({ type: types.OPERATION_IN_PROGRESS, isSilientAction: true, screen });

    let result;
    let errMessage = 'operation failed';

    try {
      let response = await funblocks_api_fetch(apiAddr, 'GET', params, timeout);
      if (response.status === 403) {
        console.log('user unauthorized');
        dispatch(logout_of_app());
        throw 'Unauthenticated user';
      } else if (response.status === 400) {
        throw 'Wrong params';
      }

      result = await response.json();
    } catch (error) {
      console.log('error msg', error);
      errMessage = error.message;
    }

    if (result && result.success == 1) {
      if (result.data) {
        let item = result.data;
        if (successHandler.action_type) {
          dispatch({ type: successHandler.action_type, item, key: genKeyByUser(getState, successHandler.action_key) });
        }

        if (successHandler.actions) {
          successHandler.actions.forEach(action => {
            const actionObj = action(item);
            !!actionObj && dispatch(actionObj);
          });
        }
      }

      if (successHandler.callback) {
        successHandler.callback(result.data);
      }

      return dispatch({
        type: types.OPERATION_SUCCESS,
        message: !successHandler.isSilientAction && (successHandler.message || result.message),
        navBack: false
      });
    }

    if (result?.code == 555) {
      dispatch({
        type: types.PROMOTE_VIP,
        value: {
          visible: true
        }
      });
      dispatch({
        type: types.OPERATION_SUCCESS,
        screen
      });

      return;
    }

    if (result && result.success == 0) {
      errMessage = result.message;
    }

    if (failureCallback) {
      failureCallback(errMessage);
    }

    dispatch({ type: types.OPERATION_FAILED, message: errMessage });
  }
}

export const invalidateTicket = (key) => invalidateLocalData(key, types.TICKET_ACTIONS);

function invalidateLocalData(key, actionTypes) {
  return async function (dispatch, getState) {
    dispatch({ key: genKeyByUser(getState, key), type: actionTypes.invalidated });
  }
}

function fetchListFailed(action_type, message, key) {
  return {
    message,
    type: action_type,
    key
  }
}

function requestListAction(action_type, key) {
  return {
    type: action_type,
    key
  }
}

function upsertedItemAction(action_type, item, params, key) {
  return {
    _id: item._id,
    item: Object.assign({}, item),
    params,
    type: action_type,
    key
  }
}
