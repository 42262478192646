import React, { useEffect, useRef, useState } from 'react';
import { Tooltip } from '@mui/material'; // 请替换为您使用的UI库
import { toPng } from 'html-to-image'; // 从 html-to-image 导入 toPng 函数
import { useIntl } from 'react-intl';
import { Close, Download } from '@styled-icons/material';

const Artifact = ({ selectedArtifact, aigc_hovered, setSelectedArtifact, color_theme }) => {
    const intl = useIntl()
    const handleDownload = () => {
        const element = document.getElementById('artifact-content');
        toPng(element)
            .then((dataUrl) => {
                const link = document.createElement('a');
                link.download = 'artifact.png';
                link.href = dataUrl;
                link.click();
            })
            .catch((error) => {
                console.error('下载图片时出错:', error);
            });
    };

    const iframeRef = useRef(null);
    const containerRef = useRef(null);
    const [iframeSize, setIframeSize] = useState({ width: '100%', height: '500px' });

    useEffect(() => {
        const iframe = iframeRef.current;
        const container = containerRef.current;
        if (!iframe || !container || selectedArtifact?.type === 'SVG') return;

        const resizeObserver = new ResizeObserver((entries) => {
            for (let entry of entries) {
                const { width, height } = entry.contentRect;
                setIframeSize({ width: `${width}px`, height: `${height}px` });
            }
        });

        const iframeContent = `
        <html>
          <head>
            <style>
              body { margin: 0; }
              #content { display: inline-block; }
            </style>
          </head>
          <body>
            <div id="content">${selectedArtifact?.content}</div>
            <script>
              const resizeObserver = new ResizeObserver((entries) => {
                for (let entry of entries) {
                  const width = entry.target.offsetWidth;
                  const height = entry.target.offsetHeight;
                  window.parent.postMessage({ type: 'resize', width, height }, '*');
                }
              });
              resizeObserver.observe(document.getElementById('content'));
            </script>
          </body>
        </html>
      `;

        iframe.srcdoc = iframeContent;

        const handleMessage = (event) => {
            if (event.source !== iframe.contentWindow) return;
            if (event.data.type === 'resize') {
                const { width, height } = event.data;
                setIframeSize({ width: `${width}px`, height: `${height}px` });
            }
        };

        window.addEventListener('message', handleMessage);

        return () => {
            window.removeEventListener('message', handleMessage);
            resizeObserver.disconnect();
        };
    }, [selectedArtifact]);

    return (
        <div
            ref={containerRef}
            style={{
                position: 'absolute',
                left: 'calc(100% + 20px)',
                top: 0,
                minWidth: 320,
                border: `1px solid #ccc`,
                backgroundColor: color_theme.content_bg,
                boxShadow: '0px 0px 8px #bbb',
            }}
        >
            <div id="artifact-content">
                {selectedArtifact?.type === 'SVG' ? (
                    <div dangerouslySetInnerHTML={{ __html: selectedArtifact.content }} />
                ) : (
                    <iframe
                        ref={iframeRef}
                        style={{
                            width: iframeSize.width,
                            height: iframeSize.height,
                            border: 'none',
                            backgroundColor: 'white',
                            overflow: 'hidden',  // 防止出现滚动条
                        }}
                    />
                )}
            </div>

            {aigc_hovered && (
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    position: 'absolute',
                    top: 0,
                    right: 0,
                }}>
                    <Tooltip
                        title={intl.formatMessage({ id: 'export_to_image' })}
                        placement='top'
                    >
                        <div
                            className='transparent-background'
                            style={{
                                width: 25,
                                height: 25,
                                color: '#777',
                                cursor: 'pointer',
                            }}
                            onClick={handleDownload}
                        >
                            <Download size={20} />
                        </div>
                    </Tooltip>

                    <Tooltip
                        title={intl.formatMessage({ id: 'close' })}
                        placement='top'
                    >
                        <div
                            className='transparent-background'
                            style={{
                                width: 25,
                                height: 25,
                                color: '#777',
                                cursor: 'pointer',
                            }}
                            onClick={() => setSelectedArtifact(null)}
                        >
                            <Close size={20} />
                        </div>
                    </Tooltip>
                </div>
            )}
        </div>
    );
};

export default Artifact;