import { useIntl } from "react-intl";
import ServiceSubscribePanel from "./ServiceSubscribePanel";
import gpt from '../../assets/images/gpt.png'
import claude from '../../assets/images/claude.png'
import gemini from '../../assets/images/gemini.png'
import { useMediaQuery } from "react-responsive";
import { MOBILE_MEDIA_QUERY } from "../../utils/constants";

const AIServiceSubscribe = () => {
    const intl = useIntl();
    const services = ['aiplus'];
    const isMobile = useMediaQuery(MOBILE_MEDIA_QUERY);

    return (
        <div className="fill-available" style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            maxWidth: isMobile ? '100%' : 1160,
            alignSelf: 'center',
            padding: isMobile ? '0 10px' : 0
        }}>
            <div style={{
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                marginTop: 40,
                fontSize: isMobile ? 20 : 26,
                fontWeight: 500,
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center'
            }}>
                {intl.formatMessage({ id: 'supports' })} &nbsp;
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap' }}>
                    <img src={gpt} style={{ width: 28, height: 28 }} />&nbsp;GPT-4,&nbsp;
                    <img src={claude} style={{ width: 28, height: 28 }} />&nbsp;Claude-3.5,&nbsp;
                    <img src={gemini} style={{ width: 28, height: 28 }} />&nbsp;Gemini-1.5-Pro
                </div>
            </div>
            <span style={{ fontSize: isMobile ? '20px' : '24px', fontWeight: 600, marginTop: 20, marginBottom: 20, textAlign: 'center' }}>
                {intl.formatMessage({ id: 'funblocks_ai_product_slogan' })}
            </span>
            <span style={{ fontSize: isMobile ? '16px' : '18px', color: '#666', textAlign: 'center' }}>
                {intl.formatMessage({ id: 'funblocks_ai_promotion_slogan' })}
            </span>

            <ServiceSubscribePanel services={services} />
        </div>
    );
}

export default AIServiceSubscribe;