import React, { useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import { loadStripe } from '@stripe/stripe-js';
import {
    PaymentElement,
    Elements,
    useStripe,
    useElements,
    CardElement
} from '@stripe/react-stripe-js';
import { useDispatch, useSelector } from 'react-redux';
import { getServingProducts, initSubscription, stripePaid } from 'src/actions/ticketAction';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, Divider } from '@mui/material';
import { AI_COINS_PRODUCTS, SERVING_PRODUCT, STRIPE_CHECKOUT_DIALOG } from 'src/constants/actionTypes';
import { useIntl } from 'react-intl';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import LoadingScreen from '../LoadingScreen';
import { Close } from '@styled-icons/material';
import { applyPromoCode, createConfirmSubscription } from '../../actions/ticketAction';

const CheckoutForm = ({ handleClose, skus, trial_days, clientSecret, invoice, service }) => {
    const stripe = useStripe();
    const elements = useElements();
    const dispatch = useDispatch();
    const intl = useIntl();
    const [errorMessage, setErrorMessage] = useState(null);
    const [loading, setLoading] = useState(false);
    const isTrialing = trial_days > 0;
    const [showPromoInput, setShowPromoInput] = useState(false); // State to control the visibility of the promotion code input
    const [showPromoApplyButton, setShowPromoApplyButton] = useState(false);
    const [promoCode, setPromoCode] = useState(''); // State to hold the promotion code

    const [isPaymentElementLoading, setIsPaymentElementLoading] = useState(true);
    const [isPaymentElementLoadStart, setIsPaymentElementLoadStart] = useState(false);
    const [coupon, setCoupon] = useState();
    const [promotion, setPromotion] = useState();

    const handlePromoApply = () => {
        // Logic to apply the promotion code goes here
        setLoading({ action: 'apply_promo_code' })
        // Reset or apply code logic as needed

        dispatch(applyPromoCode({ promoCode }, (promotion) => {
            setCoupon(promotion?.coupon);
            setPromotion(promotion);
            setLoading(false);
        }, (err) => {
            setLoading(false);
        }))
    };

    const handlePromoCodeChange = (e) => {
        setPromoCode(e.target.value);
        setShowPromoApplyButton(e.target.value?.length > 3);
    };

    const paid = (item) => {
        item && dispatch({
            type: !!item.balance ? AI_COINS_PRODUCTS : SERVING_PRODUCT,
            item
        })
        handleClose(!!item);
    }

    const handleSubmit = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setLoading(true);

        // Trigger form validation and wallet collection
        const { error: submitError } = await elements.submit();
        if (submitError) {
            setErrorMessage(submitError.message);
            setLoading(false);
            return;
        }

        const { error, confirmationToken } = await stripe.createConfirmationToken({
            elements,
            params: {
            }
        });

        if (error) {
            // This point is only reached if there's an immediate error when
            // creating the ConfirmationToken. Show the error to your customer (for example, payment details incomplete)
            setErrorMessage(error.message);
            setLoading(false);
            return;
        }

        dispatch(createConfirmSubscription({ productId: skus[0].productId, channel: 'stripe', service, confirmationTokenId: confirmationToken.id, promotionCode: promotion?.id }, async (response) => {
            setLoading(null);

            if (response?.error) {
                // Show error from server on payment form
                return setErrorMessage(response.error);
            }
            // console.log('payment intent...........', paymentIntent)
            if (response?.refreshServingProducts) {
                dispatch(getServingProducts({ services: [service] }, null, 'servicesubscribe'));
            }

            if (response?.status === "requires_action") {
                // Use Stripe.js to handle the required next action
                const {
                    error,
                    paymentIntent,
                    setupIntent
                } = await stripe.handleNextAction({
                    clientSecret: response.clientSecret
                });

                if (error) {
                    setErrorMessage(error?.message || 'No payment info');
                } else {
                    // Actions handled, show success message
                    if (paymentIntent?.status == 'succeeded' || setupIntent?.status == 'succeeded') {
                        dispatch(stripePaid({ payment_intent_client_secret: paymentIntent?.client_secret || clientSecret }, (item) => {
                            paid(item)
                        }, null, 'stripe_checkout'))
                    }
                }
            }
            // No actions needed, show success message
            if (response?.item) {
                paid(response.item)
            }
        }))
    };

    const subtotal = useMemo(() => isTrialing ? 0 : skus?.reduce((accumulator, currentSku) => {
        return accumulator + currentSku.price;
    }, 0), [isTrialing, skus])

    if (elements == null) {
        return <LoadingScreen />;
    }

    return (
        <form onSubmit={handleSubmit}>
            <div style={{ display: 'flex', flexDirection: 'row', columnGap: '20px' }}>
                <div
                    className='fill-available'
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        rowGap: '10px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>
                    {
                        !isPaymentElementLoadStart &&
                        <CircularProgress size={32} />
                    }
                    <PaymentElement
                        onLoaderStart={() => {
                            setIsPaymentElementLoading(true);
                            setIsPaymentElementLoadStart(true);
                        }}
                        onReady={() => {
                            setIsPaymentElementLoading(false);
                            setIsPaymentElementLoadStart(true);
                        }}
                    />

                    {/* Show error message to your customers */}
                    {errorMessage && <div>{errorMessage}</div>}
                </div>
                <div style={{ minWidth: 240 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', rowGap: '8px' }}>
                        <div style={{ fontSize: 16, fontWeight: 'bold', color: '#666', paddingBottom: 6 }}>
                            {intl.formatMessage({ id: 'order_summary' })}
                        </div>

                        {
                            isTrialing &&
                            <div style={{ fontSize: 17, fontWeight: 'bold', color: '#444', paddingBottom: 6 }}>
                                {intl.formatMessage({ id: 'wont_charge_today' })}
                            </div>
                        }

                        {
                            skus?.map((sku, index) => {
                                if (isTrialing) {
                                    return <div key={index + ''} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', color: '#666', fontSize: 13 }}>
                                        <div>{intl.formatMessage({ id: 'service_level_' + sku?.service_level })}</div>
                                        <div>{intl.formatMessage({ id: 'trial_vip' }, { trial_days: sku?.trial_days })}</div>
                                        <div>$0</div>
                                    </div>
                                }
                                return <div key={index + ''} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', color: '#666', fontSize: 13 }}>
                                    {
                                        !sku.coins && <div>{intl.formatMessage({ id: 'service_level_' + sku?.service_level })}</div>
                                    }
                                    {
                                        sku.coins && <div>{intl.formatMessage({ id: 'aicoin' })}</div>
                                    }
                                    {
                                        sku.coins && <div>{sku.coins}</div>
                                    }
                                    <div>{'$' + sku.price}</div>
                                </div>
                            })
                        }

                        <Divider />
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', color: '#333' }}>
                            <div>{intl.formatMessage({ id: 'sub_total' })}</div>
                            <div>
                                {
                                    '$' + subtotal
                                }
                            </div>
                        </div>

                        {/* Promotion Code Section */}
                        <div style={{ marginTop: '10px' }}>
                            {
                                coupon && <div  style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', color: '#666', fontSize: 13 }}>
                                    <div>
                                    {`${coupon.name}(${coupon.percent_off}% off, ${coupon.duration_in_months} months)`}
                                    </div>
                                    <div>
                                        {`-$${subtotal * coupon.percent_off /100 }`}
                                        </div>
                                </div>
                            }
                            {
                                !coupon &&
                                (!showPromoInput ? (
                                    <button
                                        type="button"
                                        onClick={() => setShowPromoInput(true)}
                                        style={{ color: '#007bff', backgroundColor: '#f8f8f8', border: 'none', cursor: 'pointer', padding: 7, fontSize: '14px', borderRadius: 6 }}
                                    >
                                        {intl.formatMessage({ id: 'add_promo_code' })}
                                    </button>
                                ) : (
                                    <div style={{ display: 'flex', alignItems: 'center',  columnGap: 6 }}>
                                        <input
                                            type="text"
                                            value={promoCode}
                                            onChange={handlePromoCodeChange}
                                            placeholder={intl.formatMessage({ id: 'enter_promo_code' })}
                                            style={{ padding: '6px', fontSize: '14px', flexGrow: 1, border: '1px solid #ddd', outline: 'none', borderRadius: 6 }}
                                            onBlur={() => setShowPromoInput(!!promoCode)}
                                        />
                                        {
                                            showPromoApplyButton &&
                                            <LoadingButton
                                                variant="outlined"
                                                onClick={handlePromoApply}
                                                loading={loading?.action === 'apply_promp_code'}
                                                disabled={!!loading}
                                            >
                                                {intl.formatMessage({ id: 'apply' })}
                                            </LoadingButton>
                                        }
                                    </div>
                                ))
                            }
                        </div>

                        <Divider />
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', color: '#333' }}>
                            <div>{intl.formatMessage({ id: 'checkout_total' })}</div>
                            <div>
                                {
                                    '$' + subtotal * (100 - (coupon?.percent_off || 0)) / 100
                                }
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row-reverse', width: '100%', marginTop: 20 }}>
                        <LoadingButton
                            variant="contained"
                            type="submit"
                            loading={loading?.action === 'checkout'}
                            disabled={!stripe || !elements || !!loading || !!isPaymentElementLoading}
                        >
                            {intl.formatMessage({ id: !!isTrialing ? 'trial_confirm' : 'buy' })}
                        </LoadingButton>
                    </div>
                </div>
                <div
                    style={{ position: 'absolute', right: 10, top: 10, color: 'gray', cursor: 'pointer' }}
                    disabled={loading}
                    onClick={() => handleClose()}
                >
                    <Close size={20} />
                </div>
            </div>
        </form>
    );
}

export const StripeCheckoutModal = () => {
    const dispatch = useDispatch();
    const dialogState = useSelector(state => state.uiState.stripeCheckoutDialog) || {};
    const app_config = useSelector(state => state.uiState.app_config);
    const intl = useIntl();

    const stripePromise = loadStripe(app_config.stripe_public_key);

    const options = {
        mode: 'subscription',
        amount: 0,
        currency: 'usd',
        // paymentMethodCreation: 'manual',
        // Fully customizable with appearance API.
        // appearance: {/*...*/},
    };


    const handleClose = (paid) => {
        dispatch({
            type: STRIPE_CHECKOUT_DIALOG,
            value: {
                visible: false,
                paid
            }
        })
    }

    // console.log('latest invoice.......................', dialogState.paymentIntent)

    return (
        <Dialog
            open={!!dialogState?.visible}
            onClose={() => handleClose(false)}
            scroll='paper'
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"

        >
            <DialogContent dividers={true} style={{ display: 'flex', flexDirection: 'column', rowGap: '10px' }}>
                <div style={{
                    fontWeight: 'bold',
                    fontSize: 18
                }}>
                    {dialogState.title}
                </div>

                <Elements stripe={stripePromise} options={{ ...options }}>
                    <CheckoutForm
                        // intentType={dialogState.paymentIntent?.type}
                        clientSecret={options.clientSecret}
                        skus={dialogState.skus}
                        handleClose={handleClose}
                        service={dialogState.service}
                        trial_days={dialogState.trial_days}
                    />
                </Elements>
            </DialogContent>

        </Dialog>
    );
};

