import { Position } from '@xyflow/react';
import TurndownService from 'turndown';

export function getHandlePosition(nodeA, nodeB) {
  const centerA = getNodeCenter(nodeA);
  const centerB = getNodeCenter(nodeB);

  const horizontalDiff = Math.abs(centerA.x - centerB.x);
  const verticalDiff = Math.abs(centerA.y - centerB.y);

  let position;

  // when the horizontal difference between the nodes is bigger, we use Position.Left or Position.Right for the handle
  if (horizontalDiff > verticalDiff) {
    position = centerA.x > centerB.x ? Position.Left : Position.Right;
  } else {
    // here the vertical difference between the nodes is bigger, so we use Position.Top or Position.Bottom for the handle
    position = centerA.y > centerB.y ? Position.Top : Position.Bottom;
  }

  return position;
}

// returns the position (top,right,bottom or right) passed node compared to
function getParams(nodeA, nodeB) {
  const position = getHandlePosition(nodeA, nodeB);

  const [x, y] = getHandleCoordsByPosition(nodeA, position);
  return [x, y, position];
}

function getHandleCoordsByPosition(node, handlePosition) {
  // all handles are from type source, that's why we use handleBounds.source here
  const handle = node.internals.handleBounds.source.find(
    (h) => h.position === handlePosition,
  );

  let offsetX = handle.width / 2;
  let offsetY = handle.height / 2;

  // this is a tiny detail to make the markerEnd of an edge visible.
  // The handle position that gets calculated has the origin top-left, so depending which side we are using, we add a little offset
  // when the handlePosition is Position.Right for example, we need to add an offset as big as the handle itself in order to get the correct position
  switch (handlePosition) {
    case Position.Left:
      offsetX = 0;
      break;
    case Position.Right:
      offsetX = handle.width;
      break;
    case Position.Top:
      offsetY = 0;
      break;
    case Position.Bottom:
      offsetY = handle.height;
      break;
  }

  // const x = (node.computed?.positionAbsolute?.x || node.position.x) + handle.x + offsetX;
  // const y = (node.computed?.positionAbsolute?.y || node.position.y) + handle.y + offsetY;

  const x = (node.internals?.positionAbsolute?.x || node.position?.x) + handle.x + offsetX;
  const y = (node.internals?.positionAbsolute?.y || node.position?.y) + handle.y + offsetY;


  return [x, y];
}

function getNodeCenter(node) {
  return {
    // x: (node.computed?.positionAbsolute?.x || node.position.x) + node.computed?.width / 2,
    // y: (node.computed?.positionAbsolute?.y || node.position.y) + node.computed?.height / 2,
    x: (node.internals?.positionAbsolute?.x || node.position?.x) + (node.measured?.width || node.computed?.width) / 2,
    y: (node.internals?.positionAbsolute?.y || node.position?.y) + (node.measured?.height || node.computed?.height) / 2,

  };
}

// returns the parameters (sx, sy, tx, ty, sourcePos, targetPos) you need to create an edge
export function getEdgeParams(source, target) {
  const [sx, sy, sourcePos] = getParams(source, target);
  const [tx, ty, targetPos] = getParams(target, source);

  return {
    sx,
    sy,
    tx,
    ty,
    sourcePos,
    targetPos,
  };
}

export const onContextMenu = ({ type, event, selected_text, nodeId, setContextMenu }) => {
  // Prevent native context menu from showing
  event.preventDefault();
  event.stopPropagation();

  // Calculate position of the context menu. We want to make sure it
  // doesn't get positioned off-screen.
  const pane = document.getElementById('flow_container').getBoundingClientRect();
  var rect;

  let position;
  if (type == 'text_selection') {
    var range = document.getSelection().getRangeAt(0)
    rect = range.getBoundingClientRect()

    position = {
      left: rect.left - pane.left,
      top: rect.bottom + 50 < window.innerHeight ? rect.bottom + 10 - pane.top : rect.top - 50 - pane.top,
    }

  } else {
    position = {
      top: event.clientY < pane.height - 200 && event.clientY - pane.top,
      left: event.clientX < pane.width - 200 && event.clientX - pane.left,
      right: event.clientX >= pane.width - 200 && pane.width - event.clientX + pane.left,
      bottom:
        event.clientY >= pane.height - 200 && pane.height - event.clientY + pane.top,
    }
  }

  setContextMenu({
    ...position,
    id: nodeId,
    type,
    selected_text,
    selection_rect_top: rect?.top
  });
}

function getPreferPosition(outgoersHandlePositions, countPositions, ratios) {
  // 创建一个数组来存储每个位置的计数
  const positionCounts = countPositions.map(position => 0);

  // 遍历outgoersHandlePosition数组
  outgoersHandlePositions.forEach(position => {
    // 如果该位置在countPositions中存在
    const index = countPositions.indexOf(position);
    if (index !== -1) {
      // 增加相应位置的计数
      positionCounts[index]++;
    }
  });

  // 计算加权值
  const weightedCounts = positionCounts.map((count, index) => count * ratios[index]);

  // 找到最小加权值的索引
  const minWeightedCountIndex = weightedCounts.indexOf(Math.min(...weightedCounts));

  // 返回最小加权值所在位置对应的countPositions中的元素值
  return countPositions[minWeightedCountIndex];
}

export const getNewSubNodePosition = (node, getNode, getNodeEdges) => {
  const incomerHandlePosition = getSourceHandlePosition(node.id, getNodeEdges(node.id, 'target'), getNode);
  const outgoers = getNodeEdges(node.id, 'source')?.map(edge => {
    return getNode(edge.target)
  })?.filter(node => !!node?.computed);

  let availableOutgoingHandlePosition;

  switch (incomerHandlePosition) {
    case Position.Left:
      availableOutgoingHandlePosition = [Position.Right, Position.Bottom, Position.Top]
      break;
    case Position.Top:
      availableOutgoingHandlePosition = [Position.Right, Position.Left, Position.Bottom]
      break;
    case Position.Right:
      availableOutgoingHandlePosition = [Position.Left, Position.Bottom, Position.Top]
      break;
    case Position.Bottom:
      availableOutgoingHandlePosition = [Position.Right, Position.Left, Position.Top]
      break;
    default:
      availableOutgoingHandlePosition = [Position.Right, Position.Left, Position.Bottom, Position.Top]
  }

  const outgoersHandlePositions = outgoers.map(target => {
    return getHandlePosition(node, target);
  });

  const targetPosition = getPreferPosition(outgoersHandlePositions, availableOutgoingHandlePosition, [1, 1.5, 2, 3])

  let xy;
  const { width, height } = node.measured || { width: 320, height: 50 };
  const { x, y } = node.position;
  const dimension = 600;

  switch (targetPosition) {
    case Position.Right:
      xy = {
        x: x + width + 100,
        y: y + height / 2 + ((Math.random() - 0.5) * dimension)
      };
      break;

    case Position.Left:
      xy = {
        x: x - 400,
        y: y + height / 2 + ((Math.random() - 0.5) * dimension)
      }
      break;

    case Position.Bottom:
      xy = {
        x: x + width / 2 + ((Math.random() - 0.5) * dimension),
        y: y + height + 100
      }

      break;

    case Position.Top:
      xy = {
        x: x + width / 2 + ((Math.random() - 0.5) * dimension),
        y: y - 500
      }

      break;

  }

  return xy;
}

export const copyNodeOrTextToNote = (node, text, to_node_top) => {
  let position = {
    x: node.position.x + (node.computed?.width || 320) + 50,
    y: node.position.y,
  };

  if (to_node_top) {
    position.y += to_node_top;
  }

  const { data } = node;
  let content = text || '';
  if (!content) {
    if (data.title) {
      content = `### ${data.title} \n\n`
    }

    const turndownService = new TurndownService();
    content += (data.nodeType != 'prompt' && data.content || data.userInput && turndownService.turndown(data.userInput)?.trim() || '')
  }

  return {
    id: new Date().getTime() + '',
    position,
    type: 'ai_node',
    data: {
      content: content,
      nodeType: 'note',
      color_theme: 'yellow'
    }
  };
}

export const copyNodeItemsToTaskList = (node, top) => {
  let position = {
    x: node.position.x + (node.computed?.width || 320) + 50,
    y: node.position.y,
  };

  if (top) {
    position.y = top - 120;
  }

  const { data } = node;
  const timeStamp = new Date().getTime();
  const todos = data.items.map((item, index) => {
    return {
      id: timeStamp + index,
      description: item,
      priority: 'medium'
    }
  });

  return {
    id: new Date().getTime() + '',
    position,
    type: 'ai_node',
    data: {
      title: data.title,
      aigc_done: true,
      aigc_triggered: true,
      nodeType: 'aigc',
      contentType: 'todos',
      queryType: 'todos',
      color_theme: 'azure',
      ai_action: 'flow_todolist',
      todos,
      priorities: ['high', 'medium', 'low']
    }
  };
}

export const getSourceHandlePosition = (nodeId, sourceEdges, getNode) => {
  const node = getNode(nodeId);
  if (!node || !sourceEdges?.length) {
    return null;
  }

  let sourceNode;
  for (let i = 0; i < sourceEdges.length; i++) {
    sourceNode = getNode(sourceEdges[i].source);
    if (sourceNode) break;
  }

  if (!sourceNode) {
    return null;
  }

  const handlePos = getHandlePosition(node, sourceNode);
  return handlePos

}

export const getNodeContent = (node, desired) => {
  // const node = getNode(id);
  const data = node.data || {};
  const { nodeType, title } = data;

  if (nodeType === 'prompt') {
    return data.userInput;
  } else if (nodeType === 'image') {
    return data.content
  }

  // console.log('node...........', node)
  let content = data.content;
  if (data.todos) {
    content = data.todos.map((item, index) => {
      const checkboxSymbol = item.done ? 'x' : ' ';
      const taskNumber = index + 1;
      const taskDescription = item.description;

      return `${taskNumber}. [${checkboxSymbol}] ${taskDescription}`
    }).join('\n')
  } else if (data.items) {
    content = data.items.map((item, index) => {
      return `- ${item}`
    }).join('\n')
  }

  if (content?.trim()?.startsWith(title)) {
    desired = desired || 'content'
  }

  if (desired == 'title') {
    return title
  } else if (desired == 'content') {
    return content
  } else if (desired == 'summary') {
    return data?.related_questions_topics?.summary || content
  }

  return (!!title && ('# ' + title) || '') + (!!title && !!content ? '\n\n' : '') + (content || '')
}
