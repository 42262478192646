import React, { useEffect, useState, useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeKatex from 'rehype-katex'
import remarkMath from 'remark-math'
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'
import { PrismLight as SyntaxHighlighter } from 'react-syntax-highlighter';
import vscDarkPlus from 'react-syntax-highlighter/dist/esm/styles/prism/vsc-dark-plus';
import mermaid from "mermaid";
import Modal from '@mui/material/Modal'
// 导入更多语言
import jsx from 'react-syntax-highlighter/dist/esm/languages/prism/jsx';
import javascript from 'react-syntax-highlighter/dist/esm/languages/prism/javascript';
import typescript from 'react-syntax-highlighter/dist/esm/languages/prism/typescript';
import python from 'react-syntax-highlighter/dist/esm/languages/prism/python';
import java from 'react-syntax-highlighter/dist/esm/languages/prism/java';
import csharp from 'react-syntax-highlighter/dist/esm/languages/prism/csharp';
import php from 'react-syntax-highlighter/dist/esm/languages/prism/php';
import ruby from 'react-syntax-highlighter/dist/esm/languages/prism/ruby';
import swift from 'react-syntax-highlighter/dist/esm/languages/prism/swift';
import go from 'react-syntax-highlighter/dist/esm/languages/prism/go';
import rust from 'react-syntax-highlighter/dist/esm/languages/prism/rust';
import scala from 'react-syntax-highlighter/dist/esm/languages/prism/scala';
import kotlin from 'react-syntax-highlighter/dist/esm/languages/prism/kotlin';
import sql from 'react-syntax-highlighter/dist/esm/languages/prism/sql';
import bash from 'react-syntax-highlighter/dist/esm/languages/prism/bash';
import css from 'react-syntax-highlighter/dist/esm/languages/prism/css';
import html from 'react-syntax-highlighter/dist/esm/languages/prism/markup';
import { Clipboard } from '@styled-icons/bootstrap/Clipboard';
import { ClipboardCheck } from '@styled-icons/bootstrap/ClipboardCheck'

// 注册所有导入的语言
SyntaxHighlighter.registerLanguage('jsx', jsx);
SyntaxHighlighter.registerLanguage('javascript', javascript);
SyntaxHighlighter.registerLanguage('typescript', typescript);
SyntaxHighlighter.registerLanguage('python', python);
SyntaxHighlighter.registerLanguage('java', java);
SyntaxHighlighter.registerLanguage('csharp', csharp);
SyntaxHighlighter.registerLanguage('php', php);
SyntaxHighlighter.registerLanguage('ruby', ruby);
SyntaxHighlighter.registerLanguage('swift', swift);
SyntaxHighlighter.registerLanguage('go', go);
SyntaxHighlighter.registerLanguage('rust', rust);
SyntaxHighlighter.registerLanguage('scala', scala);
SyntaxHighlighter.registerLanguage('kotlin', kotlin);
SyntaxHighlighter.registerLanguage('sql', sql);
SyntaxHighlighter.registerLanguage('bash', bash);
SyntaxHighlighter.registerLanguage('css', css);
SyntaxHighlighter.registerLanguage('html', html);

mermaid.initialize({
  startOnLoad: true,
  theme: 'default',
  securityLevel: 'loose',
});

const MermaidRenderer = ({ source, id }) => {
  const mermaidRef = useRef(null);

  useEffect(() => {
    const initializeMermaid = async () => {
      if (mermaidRef.current) {
        mermaidRef.current.innerHTML = source;
        const { svg, bindFunctions } = await mermaid.render(`mermaid-diagram-${id}`, source);
        if (mermaidRef.current) {
          mermaidRef.current.innerHTML = svg;
          bindFunctions?.(mermaidRef.current);
        }
      }
    };

    initializeMermaid();

    // Clean up mermaid instance when unmounting; doing nothing at the momemt
    return () => {

    };
  }, [source]);

  return <div id={id} ref={mermaidRef}></div>;
};

const CodeBlockRenderer = ({ props, value, language }) => {
  const [copied, setCopied] = useState(false);
  const handleCopy = (value) => {
    navigator.clipboard.writeText(value);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return <div style={{ position: 'relative' }}>
    <SyntaxHighlighter
      style={vscDarkPlus}
      language={language}
      showLineNumbers={true}
      wrapLines={true}
      customStyle={{
        borderRadius: 4
      }}
      PreTag="div"
      {...props}
    >
      {value}
    </SyntaxHighlighter>
    <button
      onClick={() => handleCopy(value)}
      style={{
        position: 'absolute',
        top: 0,
        right: 0,
        background: 'none',
        border: 'none',
        color: 'white',
        cursor: 'pointer',
        padding: '5px',
        color: copied ? 'lightskyblue' : 'white'
      }}
    >
      {copied && <ClipboardCheck color={'lightskyblue'} size={16} />}
      {!copied && <Clipboard size={16} />}
      {copied ? 'Copid' : 'Copy'}
    </button>
  </div>
}

const ArtifactButton = ({ onClick, children }) => (
  <button
    onClick={onClick}
    style={{
      padding: '5px 10px',
      margin: '5px',
      backgroundColor: '#f0f0f0',
      border: '1px solid #ccc',
      borderRadius: '4px',
      cursor: 'pointer',
    }}
  >
    {children}
  </button>
);

const MarkdownRenderer = ({ content, artifacts, setArtifacts, setSelectedArtifact }) => {
  const [processedContent, setProcessedContent] = useState('');
  // const [artifacts, setArtifacts] = useState([]);
  // const [selectedArtifact, setSelectedArtifact] = useState(null);

  useEffect(() => {
    const extractArtifacts = (markdown) => {
      const artifactRegex = /<(svg|html)[\s\S]*?<\/\1>/gi;
      const extractedArtifacts = [];
      let artifactId = 0;
      const processedMarkdown = markdown.replace(artifactRegex, (match) => {
        const type = match.startsWith('<svg') ? 'SVG' : 'HTML';
        extractedArtifacts.push({ id: artifactId, type, content: match });
        const placeholder = `[ARTIFACT_${artifactId}]`;
        artifactId++;
        return placeholder;
      });

      setArtifacts(extractedArtifacts);
      return processedMarkdown;
    };

    const processedMarkdown = setArtifacts?  extractArtifacts(content) : content;
    setProcessedContent(processedMarkdown.replace(/(\S)?(\*\*.*?\*\*)(\S)?/g, (match, p1, p2, p3) => {
      const before = p1 ? p1 + ' ' : '';
      const after = p3 ? ' ' + p3 : '';
      return before + p2 + after;
    }));
  }, [content]);

  const renderArtifactButton = (id) => {
    const artifact = artifacts.find(a => a.id === id);
    return (
      <ArtifactButton onClick={() => setSelectedArtifact(artifact)}>
        View {artifact.type} Content
      </ArtifactButton>
    );
  };

  // console.log('selected artifacts................', selectedArtifact)
  return (
      <ReactMarkdown
        remarkPlugins={[
          remarkGfm,
          remarkMath
        ]}
        rehypePlugins={[rehypeKatex, rehypeRaw]}
        components={{
          p({ node, children }) {
            if (typeof children === 'string' && children.includes('[ARTIFACT_')) {
              const artifactId = parseInt(children.match(/\d+/)[0], 10);
              return renderArtifactButton(artifactId);
            }
            return <p>{children}</p>;
          },
          code({ node, inline, className, children, ...props }) {
            const match = /language-(\w+)/.exec(className || '')

            const value = String(children).replace(/\n$/, '');
            
            if (typeof children === 'string' && value.includes('[ARTIFACT_')) {
              const artifactId = parseInt(value.match(/\d+/)[0], 10);
              return renderArtifactButton(artifactId);
            }

            if (match && match[1] === 'html') {  
              return <div dangerouslySetInnerHTML={{ __html: value }} />
            }

            if (match && match[1] === 'mermaid') {
              return <MermaidRenderer id={'mermaid-chart'} source={value} />
            }

            return !inline && match ? (
              <CodeBlockRenderer
                props={props}
                language={match[1]}
                value={value}
              />
            ) : (
              <code className={className} {...props
              } >
                {children}
              </code >
            )
          }
        }}
      >
        {processedContent}
      </ReactMarkdown >
  );
};

export default MarkdownRenderer;